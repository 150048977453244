import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import { Container, Col, Row, Modal, Card } from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";

import loadingAnimation from "assets/img/loading.gif";

let animateTimer;

class Index extends React.Component {
  componentDidMount() {
    // document.body.classList.toggle("index-page");
    // this.timer = setInterval(() => {
    //   if (
    //     document.getElementById("top").complete &&
    //     document.getElementById("bottom").complete
    //   ) {
    //     document.getElementById("top").style.display = "block";
    //     document.getElementById("bottom").style.display = "block";
    //     clearInterval(this.timer);
    //   }
    // }, 100);
    //refers
    let params = new URLSearchParams(this.props.location.search);
    if (params.get("referrer")) {
      let referrer = params.get("referrer");
      localStorage.setItem("referrer", referrer);
    }
    this.loader();
    this.animate();
  }
  loader = () => {
    for (let i = 1; i <= 3; i++) {
      let timer = setInterval(() => {
        if (
          document.getElementById(`top${i}`).complete &&
          document.getElementById(`bottom${i}`).complete
        ) {
          document.getElementById(`top${i}`).style.display = "block";
          document.getElementById(`bottom${i}`).style.display = "block";
          clearInterval(timer);
        } else {
          document.getElementById(`top${i}`).style.display = "none";
          document.getElementById(`bottom${i}`).style.display = "none";
        }
      }, 100);
    }
  };
  animate = () => {
    animateTimer = setInterval(() => {
      this.lights();
    }, 2000);
  };
  componentWillUnmount() {
    clearInterval(animateTimer);
    // document.body.classList.toggle("index-page");
  }

  constructor(props) {
    super(props);
    this.state = { lights: false, videoModal: false };
  }
  lights = () => {
    this.setState({ lights: !this.state.lights });
  };
  toggleModal = (modalState) => {
    this.setState({
      [modalState]: !this.state[modalState],
    });
    if (modalState === "lithoModal") {
      let el = document.getElementById("photoSubmit");
      if (el) {
        el.firstChild.data = "Submit";
        el.disabled = false;
      }
    }
  };
  render() {
    return (
      <>
        <IndexNavbar
          getUserInfo={this.getUserInfo}
          loginModal={this.state.loginModal}
          signUpModal={this.state.signUpModal}
          toggleModal={this.toggleModal}
        />
        <div className="wrapper">
          <div className="page-header header-filter" id="background">
            <div
              className="overlay"
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                height: "100%",
                width: "100%",
                backgroundColor: "#000",
                opacity: ".5",
                zIndex: "1",
              }}
            ></div>

            <Container id="container">
              <Row>
                <Col lg="6" className=" my-auto py-5">
                  <h1 className="title ">Gift a Magical gift.</h1>
                  <h4 className=" ">
                    Your search for Unique and Custom gift ends here! Gift
                    Wizards makes amazing 3D Printed Lithophanes from your
                    chosen photos that you can gift your loved friends and
                    family members.
                  </h4>
                  <br />
                  <Link
                    to="/products/lithophane"
                    className="btn btn-success btn-raised btn-lg"
                  >
                    Make yours now!
                  </Link>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="page-body">
            <div className="container">
              <div className="section text-center">
                <div className="row">
                  <div className="col">
                    <h2 className="title">Lithophanes</h2>
                    <h4 className="description">
                      A Lithophane is a piece of artwork that appears
                      meaningless at first glance but shows a clear picture when
                      back lit. In the older times, they were carved out of
                      porcelain. Now we use advanced 3D Printing technologies to
                      make them out of bio degradable PLA. Click the button
                      below for an explanation about Lithophanes if you are
                      still puzzled.
                    </h4>
                    <button
                      className="btn btn-danger btn-raised btn-lg"
                      onClick={() => {
                        this.toggleModal("videoModal");
                      }}
                    >
                      <i className="fas fa-play" /> Watch video
                    </button>
                  </div>
                </div>

                <Row>
                  {/* single photo */}
                  <Col md="4">
                    <div className="info">
                      <Card>
                        <div className="card-image">
                          <Link to="/products/lithophane">
                            <div id="repBox">
                              <img
                                src={loadingAnimation}
                                alt=""
                                style={{ top: "0" }}
                              />
                              <img
                                alt="..."
                                id="bottom1"
                                className="img-center img-fluid "
                                src={`/pics/repBox/singlePhoto/Plane/Lights_on.jpg`}
                                srcSet={[
                                  `/pics/repBox/singlePhoto/Plane/Lights_on.jpg-xsmall.jpg 400w`,
                                  `/pics/repBox/singlePhoto/Plane/Lights_on.jpg-small.jpg 600w`,
                                  `/pics/repBox/singlePhoto/Plane/Lights_on.jpg-medium.jpg 1000w`,
                                  // `/pics/repBox/singlePhoto/Plane/Lights_on.jpg-large.jpg 1200w`,
                                  // `/pics/repBox/singlePhoto/Plane/Lights_on.jpg-xlarge.jpg 1600w`
                                ]}
                                style={{ display: "none", top: "0" }}
                              />
                              <img
                                alt="..."
                                id="top1"
                                className={
                                  this.state.lights
                                    ? "transparent img-center img-fluid "
                                    : "img-center img-fluid "
                                }
                                src={`/pics/repBox/singlePhoto/Plane/Lights_off.jpg`}
                                srcSet={[
                                  `/pics/repBox/singlePhoto/Plane/Lights_off.jpg-xsmall.jpg 400w`,
                                  `/pics/repBox/singlePhoto/Plane/Lights_off.jpg-small.jpg 600w`,
                                  `/pics/repBox/singlePhoto/Plane/Lights_off.jpg-medium.jpg 1000w`,
                                  // `/pics/repBox/singlePhoto/Plane/Lights_off.jpg-large.jpg 1200w`,
                                  // `/pics/repBox/singlePhoto/Plane/Lights_off.jpg-xlarge.jpg 1600w`
                                ]}
                                importance="high"
                                style={{ display: "none", top: "0" }}
                              />
                            </div>
                            {/* <img
                              alt="..."
                              id="bottom"
                              className="img-center img-fluid "
                              src={`/pics/repBox/singlePhoto/Plane/Lights_on.jpg-xlarge.jpg`}
                              srcSet={[
                                `/pics/repBox/singlePhoto/Plane/Lights_on.jpg-xsmall.jpg 400w`,
                                `/pics/repBox/singlePhoto/Plane/Lights_on.jpg-small.jpg 600w`,
                                `/pics/repBox/singlePhoto/Plane/Lights_on.jpg-medium.jpg 1000w`
                                // `/pics/repBox/singlePhoto/Plane/Lights_on.jpg-large.jpg 1200w`,
                                // `/pics/repBox/singlePhoto/Plane/Lights_on.jpg-xlarge.jpg 1600w`
                              ]}
                              // style={{ display: "none" }}
                            /> */}
                            {/* <img
                                className="img"
                                src="/pics/repBox/singlePhoto/Plane/Lights_on.jpg"
                              /> */}
                          </Link>
                        </div>
                        <div className="card-body">
                          <h4 className="info-title">Single Photo</h4>
                          <p style={{ minHeight: "160px" }}>
                            A single photo is converted into either a plane or a
                            curved Lithophane of your chosen size. They need to
                            be held against either the sun or indoor light to
                            show your photo. They do come with optional stands
                            with lights.
                          </p>
                        </div>
                      </Card>
                    </div>
                  </Col>

                  {/* cube */}

                  <Col md="4">
                    <div className="info">
                      <Card>
                        <div className="card-image">
                          <Link to="/products/lithophane">
                            <div id="repBox">
                              <img
                                src={loadingAnimation}
                                alt=""
                                style={{ top: "0" }}
                              />
                              <img
                                alt="..."
                                id="bottom2"
                                className="img-center img-fluid "
                                src={`/pics/repBox/cube/5/Lights_on.jpg`}
                                srcSet={[
                                  `/pics/repBox/cube/5/Lights_on.jpg-xsmall.jpg 400w`,
                                  `/pics/repBox/cube/5/Lights_on.jpg-small.jpg 600w`,
                                  `/pics/repBox/cube/5/Lights_on.jpg-medium.jpg 1000w`,
                                  // `/pics/repBox/cube/5/Lights_on.jpg-large.jpg 1200w`,
                                  // `/pics/repBox/cube/5/Lights_on.jpg-xlarge.jpg 1600w`
                                ]}
                                style={{ display: "none", top: "0" }}
                              />
                              <img
                                alt="..."
                                id="top2"
                                className={
                                  this.state.lights
                                    ? "transparent img-center img-fluid "
                                    : "img-center img-fluid "
                                }
                                src={`/pics/repBox/cube/5/Lights_off.jpg`}
                                srcSet={[
                                  `/pics/repBox/cube/5/Lights_off.jpg-xsmall.jpg 400w`,
                                  `/pics/repBox/cube/5/Lights_off.jpg-small.jpg 600w`,
                                  `/pics/repBox/cube/5/Lights_off.jpg-medium.jpg 1000w`,
                                  // `/pics/repBox/cube/5/Lights_off.jpg-large.jpg 1200w`,
                                  // `/pics/repBox/cube/5/Lights_off.jpg-xlarge.jpg 1600w`
                                ]}
                                importance="high"
                                style={{ display: "none", top: "0" }}
                              />
                            </div>
                            {/* <img
                              alt="..."
                              id="bottom"
                              className="img-center img-fluid "
                              src={`/pics/repBox/cube/5/Lights_on.jpg-xlarge.jpg`}
                              srcSet={[
                                `/pics/repBox/cube/5/Lights_on.jpg-xsmall.jpg 400w`,
                                `/pics/repBox/cube/5/Lights_on.jpg-small.jpg 600w`,
                                `/pics/repBox/cube/5/Lights_on.jpg-medium.jpg 1000w`,
                                // `/pics/repBox/cube/5/Lights_on.jpg-large.jpg 1200w`,
                                // `/pics/repBox/cube/5/Lights_on.jpg-xlarge.jpg 1600w`
                              ]}
                              // style={{ display: "none" }}
                            /> */}
                            {/* <img
                                className="img"
                                src="/pics/repBox/singlePhoto/Plane/Lights_on.jpg"
                              /> */}
                          </Link>
                        </div>
                        <div className="card-body">
                          <h4 className="info-title">Cube</h4>
                          <p style={{ minHeight: "160px" }}>
                            When a Lithophane of a single photo is too little,
                            you go for a cube with 4 or better, 5 photos! These
                            are perfect gifts to keep your favorite memories
                            right by your desk. There is a built in light source
                            inside to light up your memories!
                          </p>
                        </div>
                      </Card>
                    </div>
                  </Col>

                  {/* keychain */}
                  <Col md="4">
                    <div className="info">
                      <Card>
                        <div className="card-image">
                          <Link to="/products/lithophane">
                            <div id="repBox">
                              <img
                                src={loadingAnimation}
                                alt=""
                                style={{ top: "0" }}
                              />
                              <img
                                alt="..."
                                id="bottom3"
                                className="img-center img-fluid "
                                src={`/pics/repBox/keychain/Lights_on.jpg`}
                                srcSet={[
                                  `/pics/repBox/keychain/Lights_on.jpg-xsmall.jpg 400w`,
                                  `/pics/repBox/keychain/Lights_on.jpg-small.jpg 600w`,
                                  `/pics/repBox/keychain/Lights_on.jpg-medium.jpg 1000w`,
                                  // `/pics/repBox/keychain/Lights_on.jpg-large.jpg 1200w`,
                                  // `/pics/repBox/keychain/Lights_on.jpg-xlarge.jpg 1600w`
                                ]}
                                style={{ display: "none", top: "0" }}
                              />
                              <img
                                alt="..."
                                id="top3"
                                className={
                                  this.state.lights
                                    ? "transparent img-center img-fluid "
                                    : "img-center img-fluid "
                                }
                                src={`/pics/repBox/keychain/Lights_off.jpg`}
                                srcSet={[
                                  `/pics/repBox/keychain/Lights_off.jpg-xsmall.jpg 400w`,
                                  `/pics/repBox/keychain/Lights_off.jpg-small.jpg 600w`,
                                  `/pics/repBox/keychain/Lights_off.jpg-medium.jpg 1000w`,
                                  // `/pics/repBox/keychain/Lights_off.jpg-large.jpg 1200w`,
                                  // `/pics/repBox/keychain/Lights_off.jpg-xlarge.jpg 1600w`
                                ]}
                                importance="high"
                                style={{ display: "none", top: "0" }}
                              />
                            </div>
                            {/* <img
                              alt="..."
                              id="bottom"
                              className="img-center img-fluid "
                              src={`/pics/repBox/keychain/Lights_on.jpg-xlarge.jpg`}
                              srcSet={[
                                `/pics/repBox/keychain/Lights_on.jpg-xsmall.jpg 400w`,
                                `/pics/repBox/keychain/Lights_on.jpg-small.jpg 600w`,
                                `/pics/repBox/keychain/Lights_on.jpg-medium.jpg 1000w`,
                                // `/pics/repBox/keychain/Lights_on.jpg-large.jpg 1200w`,
                                // `/pics/repBox/keychain/Lights_on.jpg-xlarge.jpg 1600w`
                              ]}
                              // style={{ display: "none" }}
                            /> */}
                            {/* <img
                                className="img"
                                src="/pics/repBox/singlePhoto/Plane/Lights_on.jpg"
                              /> */}
                          </Link>
                        </div>
                        <div className="card-body">
                          <h4 className="info-title">Keychain</h4>
                          <p style={{ minHeight: "160px" }}>
                            Keep your loved ones by your side at all times with
                            our Lithophane keychains! Incredibly small in size
                            but big in memories. You will never tire of seeing
                            them or showing them off.
                          </p>
                        </div>
                      </Card>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="row">
                <div className="col text-center">
                  <h2 className="title">Perfect for</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="card card-blog">
                    <div className="card-image">
                      <a href="#pablo">
                        <img
                          alt="..."
                          className="img-center img-fluid "
                          src={`/pics/misc/birthday.jpg-medium.jpg`}
                          srcSet={[
                            `/pics/misc/birthday.jpg-xsmall.jpg 400w`,
                            `/pics/misc/birthday.jpg-small.jpg 600w`,
                            `/pics/misc/birthday.jpg-medium.jpg 1000w`,
                            // `/pics/misc/birthday.jpg-large.jpg 1200w`,
                            // `/pics/misc/birthday.jpg-xlarge.jpg 1600w`
                          ]}
                          // style={{ display: "none" }}
                        />
                        {/* <div className="card-title">
                        This Birthday Will be Awesome!
                      </div> */}
                      </a>
                    </div>
                    <div className="card-body" style={{ minHeight: "175px" }}>
                      <h6 className="category text-info">Birthday</h6>
                      <p className="card-description">
                        When you wish to gift your friend a gift they won't
                        throw away into the bin or attic, a Lithophane from Gift
                        Wizards does the perfect job!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card card-blog">
                    <div className="card-image">
                      <a href="#pablo">
                        <img
                          alt="..."
                          className="img-center img-fluid "
                          src={`/pics/misc/wedding.jpg-medium.jpg`}
                          srcSet={[
                            `/pics/misc/wedding.jpg-xsmall.jpg 400w`,
                            `/pics/misc/wedding.jpg-small.jpg 600w`,
                            `/pics/misc/wedding.jpg-medium.jpg 1000w`,
                            // `/pics/misc/wedding.jpg-large.jpg 1200w`,
                            // `/pics/misc/wedding.jpg-xlarge.jpg 1600w`
                          ]}
                          // style={{ display: "none" }}
                        />
                        {/* <div className="card-title">
                        This Birthday Will be Awesome!
                      </div> */}
                      </a>
                    </div>
                    <div className="card-body" style={{ minHeight: "175px" }}>
                      <h6 className="category text-warning">Wedding</h6>
                      <p className="card-description">
                        People gift all sorts of items to couple on their
                        special day of marriage. Stand out with a Lithophane
                        Cube from Gift Wizards and give the couple something to
                        remember and smile.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card card-blog">
                    <div className="card-image">
                      <a href="#pablo">
                        <img
                          alt="..."
                          className="img-center img-fluid "
                          src={`/pics/misc/valentines.jpg-medium.jpg`}
                          srcSet={[
                            `/pics/misc/valentines.jpg-xsmall.jpg 400w`,
                            `/pics/misc/valentines.jpg-small.jpg 600w`,
                            `/pics/misc/valentines.jpg-medium.jpg 1000w`,
                            // `/pics/misc/valentines.jpg-large.jpg 1200w`,
                            // `/pics/misc/valentines.jpg-xlarge.jpg 1600w`
                          ]}
                          // style={{ display: "none" }}
                        />
                        {/* <div className="card-title">
                        This Birthday Will be Awesome!
                      </div> */}
                      </a>
                    </div>
                    <div className="card-body" style={{ minHeight: "175px" }}>
                      <h6 className="category text-danger">Valentine's</h6>
                      <p className="card-description">
                        Roses are the one of the greatest symbols of love. But
                        pretty much everyone gifts one. You know what to gift
                        your love to make them feel even more special! A gift
                        Wizards Lithophane.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="section text-center">
                <h2 className="title">What are you waiting for?</h2>
                <Link
                  to="/products/lithophane"
                  className="btn btn-success btn-raised btn-lg"
                >
                  Make yours now!
                </Link>
              </div>
              <div className="testimonials-3">
                <div className="container">
                  <div className="row">
                    <div className="col text-center">
                      <h2 className="title">What Clients Say</h2> <br />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 text-center">
                      <div className="card card-testimonial card-plain">
                        <div className="card-avatar">
                          <a href="#pablo">
                            {/* <img
                              className="img"
                              src="assets/img/faces/card-profile1-square.jpg"
                            /> */}
                          </a>
                        </div>
                        <div className="card-content">
                          <h4 className="card-title">Sathish</h4>
                          <h6 className="category text-muted">
                            Purchased a Lithophane Cube
                          </h6>
                          <h5 className="card-description">
                            "Gift Wizards have gone out of their way to deliver
                            amazing products since it’s inception. I recently
                            bought a cube for my friend and she loved it! I
                            would definitely recommend Gift Wizards. Give them a
                            try!"
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 text-center">
                      <div className="card card-testimonial card-plain">
                        <div className="card-avatar">
                          <a href="#pablo">
                            {/* <img
                              className="img"
                              src="assets/img/faces/card-profile4-square.jpg"
                            /> */}
                          </a>
                        </div>
                        <div className="card-content">
                          <h4 className="card-title">Aishwarya</h4>
                          <h6 className="category text-muted">
                            Purchased a Lithophane Cube
                          </h6>
                          <h5 className="card-description">
                            "It’s a pleasure to do business with them since they
                            are flexible, never seem to say ‘NO’, come up with
                            solutions in case of an urgent need and are always
                            willing to deliver the best product. My best wishes
                            to Gift Wizards. If I need to gift something, I will
                            definitely buy from here."
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 text-center">
                      <div className="card card-testimonial card-plain">
                        <div className="card-avatar">
                          <a href="#pablo">
                            {/* <img
                              className="img"
                              src="assets/img/faces/card-profile6-square.jpg"
                            /> */}
                          </a>
                        </div>
                        <div className="card-content">
                          <h4 className="card-title">Monika</h4>
                          <h6 className="category text-muted">
                            Purchased a Single Photo Lithophane
                          </h6>
                          <h5 className="card-description">
                            "I had to buy a gift for a very good friend and was
                            on the lookout for something unique. I heard about
                            Gift Wizards' Lithophane and was intrigued. I gifted
                            it to my friend at her birthday party and it was an
                            instant hit among all my friends!"
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
        <Modal
          modalClassName="modal-black modal-xl"
          isOpen={this.state.videoModal}
          toggle={() => this.toggleModal("videoModal")}
          size="lg"
          style={{ marginTop: "-50px" }}
        >
          <div className="modal-header justify-content-center mb-3">
            <button
              className="close"
              onClick={() => this.toggleModal("videoModal")}
            >
              <i className="tim-icons icon-simple-remove text-white" />
            </button>
            {/* <h2>Lithophanes</h2> */}
          </div>
          <div className="modal-body text-center">
            <iframe
              title="3D Model"
              width="100%"
              height="480px"
              src="https://www.youtube.com/embed/4g1eD0xOPAI"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </Modal>
      </>
    );
  }
}

export default Index;
