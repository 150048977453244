import React, { Component } from "react";
import Parse from "parse";
import PerfectScrollbar from "perfect-scrollbar";
import Dropzone from "react-dropzone";

import classnames from "classnames";

// import ToggleButton from "react-toggle-button";
import Switch from "react-bootstrap-switch";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.min.css";

import "assets/css/jasny-bootstrap.min.css";

import loadingAnimation from "assets/img/loading.gif";

// import {
//   progressBarFetch,
//   setOriginalFetch,
//   ProgressBar
// } from "react-fetch-progressbar";

import {
  Button,
  Form,
  Container,
  Row,
  Col,
  Modal,
  Label,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  UncontrolledTooltip,
} from "reactstrap";

import IndexNavbar from "components/Navbars/IndexNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";

import avatar from "../../assets/img/avatar-placeholder.png";
// import repBox from "../../assets/img/representationBox";

let ps = null;

class Lithophane extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      lithoCategory: "Single Photo",
      lithoStyle: "Plane",
      lithoSize: 75,
      lithoModal: false,
      aspectRatio: 16 / 9,
      alignment: "Horizontal",
      cropResult: null,
      numPhotos: 1,
      cylinderMode: "Panorama",
      holderStyle: "Fixed",
      holderPower: "Plug",
      holderFor: "Single Photo",
      singlePhotoHolderType: "Minimal",
      photo: 1,
      photo1: null,
      photo2: null,
      photo3: null,
      photo4: null,
      photo5: null,
      photo6: null,
      photo7: null,
      photo8: null,
      photo9: null,
      photo10: null,
      src: null,
      repBoxPath: ``,
      lights: false,
      estimatedPrice: 0,
      loginModal: false,
      allGood: false,
      addToCartState: "",
    };
  }

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    document.body.classList.toggle("profile-page");
    this.updatePriceEstimate();
    this.repBoxPathUpdate();
    // this.getUserInfo();
    this.loader();
  }
  loader = () => {
    let timer = setInterval(() => {
      // console.log("timer");
      if (
        document.getElementById("top").complete &&
        document.getElementById("bottom").complete
      ) {
        document.getElementById("top").style.display = "block";
        document.getElementById("bottom").style.display = "block";
        clearInterval(timer);
      } else {
        document.getElementById("top").style.display = "none";
        document.getElementById("bottom").style.display = "none";
      }
    }, 100);
  };
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
    document.body.classList.toggle("profile-page");
  }
  async componentDidUpdate(prevProps, prevState) {
    if (
      prevState.lithoCategory !== this.state.lithoCategory ||
      prevState.lithoStyle !== this.state.lithoStyle ||
      prevState.numPhotos !== this.state.numPhotos
    ) {
      this.repBoxPathUpdate();
    }
    if (this.state.lithoCategory === "Cube" && this.state.photo === 5) {
      // console.log(
      //   `Cube set top aspect ratio: PrevState: ${prevState.aspectRatio}, this.state: ${this.state.aspectRatio}`
      // );
      if (prevState.aspectRatio !== 1 && this.state.aspectRatio !== 1) {
        await this.setState({ aspectRatio: 1 });
      } else if (prevState.aspectRatio === 1 && this.state.aspectRatio !== 1) {
        await this.setState({ photo: 1 });
      }
    }
    if (prevState.aspectRatio !== this.state.aspectRatio) {
      // console.log(
      //   `aspect ratio change: PrevState: ${prevState.aspectRatio}, this.state: ${this.state.aspectRatio}`
      // );
      // console.log(this.state.lithoCategory + " " + this.state.numPhotos);
      if (this.state.lithoCategory === "Cube" && this.state.photo === 5) {
        if (prevState.aspectRatio === 1 && this.state.aspectRatio !== 1) {
          for (let i = 1; i <= this.state.numPhotos; i++) {
            let currentPhoto = "photo" + i;
            this.setState({ [currentPhoto]: null, src: null });
          }
        } else {
        }
        // if (prevState.aspectRatio !== 1) {
        //   this.setState({ aspectRatio: 1 });
        // }
      } else {
        for (let i = 1; i <= this.state.numPhotos; i++) {
          let currentPhoto = "photo" + i;
          this.setState({ [currentPhoto]: null, src: null });
        }
      }
    }
    if (
      this.state.lights === prevState.lights &&
      this.state.repBoxPath !== prevState.repBoxPath
    ) {
      this.loader();
    }

    if (prevState.estimatedPrice === this.state.estimatedPrice) {
      this.updatePriceEstimate();
    }
  }
  toggleTabs = (e, stateName, index) => {
    e.preventDefault();
    this.setState({
      [stateName]: index,
    });
  };

  getUserInfo = () => {
    let user = Parse.User.current();
    if (user) {
      this.setState({
        isLoggedIn: true,
        firstName: user.get("firstName"),
        lastName: user.get("lastName"),
        email: user.get("email"),
        address: user.get("address"),
        city: user.get("city"),
        state: user.get("state"),
      });
      if (user.get("phone")) {
        this.setState({ phone: user.get("phone") });
      }
      if (user.get("pincode")) {
        this.setState({ pincode: user.get("pincode") });
      }
      if (user.get("profilePic")) {
        this.setState({
          profilePicUrl: user.get("profilePic").url(),
        });
      } else {
        this.setState({
          profilePicUrl: avatar,
        });
      }
    }
  };

  toggleModal = (modalState) => {
    this.setState({
      [modalState]: !this.state[modalState],
    });
    if (modalState === "lithoModal") {
      let el = document.getElementById("photoSubmit");
      if (el) {
        el.firstChild.data = "Submit";
        el.disabled = false;
      }
    }
  };

  onFilesAccepted = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      var dataURL = reader.result;
      this.setState({ src: dataURL });
    };
    try {
      reader.readAsDataURL(file[0]);
    } catch (error) {
      console.log(error);
    }
  };

  cropImage = async () => {
    let currentPhoto = "photo" + this.state.photo;
    try {
      await this.setState({
        [currentPhoto]: this.cropper.getCroppedCanvas().toDataURL("image/jpeg"),
      });
      let el = document.getElementById("warning");
      if (el.style.display === "block") {
        el.innerHTML = `Remaining photos to be uploaded: `;
        let flag = false;
        for (let i = 1; i <= this.state.numPhotos; i++) {
          let currentPhoto = "photo" + i;
          if (this.state[currentPhoto] === null) {
            el.innerHTML += `${i}, `;
            el.style.display = "block";
            flag = true;
          }
          if (!flag) {
            el.style.display = "None";
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  submitPhoto = async (e) => {
    e.preventDefault();
    let el = document.getElementById("photoSubmit");
    el.firstChild.data = "Submitting";
    el.disabled = true;
    await this.cropImage();
    this.toggleModal("lithoModal");
  };

  updatePriceEstimate = async () => {
    let {
      lithoCategory,
      aspectRatio,
      numPhotos,
      lithoSize,
      estimatedPrice,
      holderFor,
      singlePhotoHolderType,
    } = this.state;
    const keychainRate = 1 / 9;
    const singlePhotoRate = 300 / 5625;
    const cubeRate = 1500 / 28125;
    const cylinderRate = 1500 / 28125;
    const singlePhotoMinimalHolderRate = 150 / 5625;
    const singlePhotoCompleteHolderRate = 300 / 5625;
    const cubeHolderRate = 500 / 28125;
    let smallerSide;
    let largerSide;
    if (lithoCategory === "Keychain") {
      smallerSide = lithoSize;
    } else {
      largerSide = lithoSize;
    }
    let newPrice;

    let holderRate = 0;
    if (holderFor === "Single Photo" || lithoCategory === "Single Photo") {
      if (singlePhotoHolderType === "Minimal") {
        holderRate = singlePhotoMinimalHolderRate;
      } else if (singlePhotoHolderType === "Complete") {
        holderRate = singlePhotoCompleteHolderRate;
      }
    } else if (holderFor === "Cube" || lithoCategory === "Cube") {
      holderRate = cubeHolderRate;
    }

    if (largerSide) {
      smallerSide = largerSide / aspectRatio;
    } else {
      largerSide = smallerSide * aspectRatio;
    }
    const area = numPhotos * smallerSide * largerSide;
    // console.log(`small: ${smallerSide}, large: ${largerSide}`);
    let holderPrice = area * holderRate;
    switch (lithoCategory) {
      case "Single Photo":
        newPrice =
          Math.floor((area * singlePhotoRate + holderPrice) * 100) / 100;
        break;
      case "Keychain":
        newPrice = Math.floor(area * keychainRate * 100) / 100;
        break;
      case "Cube":
        newPrice = Math.floor(area * cubeRate * 100) / 100;
        break;
      case "Cylinder":
        const cylinderDia = lithoSize;
        const photoLength = (3.14 * cylinderDia) / numPhotos;
        const photoWidth = photoLength / aspectRatio;
        const surfaceArea = 3.14 * cylinderDia * photoWidth;
        newPrice = Math.floor(surfaceArea * cylinderRate * 100) / 100;
        break;
      case "Holder":
        newPrice = Math.floor(holderPrice * 100) / 100;
        break;

      default:
        console.log("Default");
        break;
    }
    if (newPrice !== estimatedPrice) {
      this.setState({ estimatedPrice: newPrice });
    }
  };

  onAddToCart = async (e) => {
    let el = document.getElementById(e.target.id);
    let user = Parse.User.current();
    let date = new Date();
    if (user) {
      await this.setState({ allGood: true });
      for (let i = 1; i <= this.state.numPhotos; i++) {
        let currentPhoto = "photo" + i;
        if (this.state[currentPhoto] == null) {
          await this.setState({ allGood: false });
          let el = document.getElementById("warning");
          el.innerHTML += `${i}, `;
          el.style.display = "block";
        }
      }
      if (this.state.allGood) {
        el.innerHTML = "Just a moment...";
        el.disabled = true;
        let newCartItem = new Parse.Object("Items");
        for (let i = 1; i <= this.state.numPhotos; i++) {
          let currentPhoto = "photo" + i;
          let parseFile = await new Parse.File(
            `${user.getUsername()}_${date.getUTCFullYear()}_${date.getUTCMonth()}_${date.getUTCDate()}_${date.getUTCHours()}_${date.getUTCMinutes()}_${date.getUTCSeconds()}_${currentPhoto}.jpg`,
            {
              base64: this.state[currentPhoto],
            }
          );
          await newCartItem.set(currentPhoto, parseFile);
        }
        newCartItem.set("lithoCategory", this.state.lithoCategory);
        newCartItem.set("lithoSize", this.state.lithoSize);
        newCartItem.set("lithoStyle", this.state.lithoStyle);
        newCartItem.set("aspectRatio", this.state.aspectRatio);
        newCartItem.set("numPhotos", this.state.numPhotos);
        if (this.state.lithoCategory === "Cylinder") {
          newCartItem.set("cylinderMode", this.state.cylinderMode);
        }
        newCartItem.set("holderStyle", this.state.holderStyle);
        newCartItem.set("holderPower", this.state.holderPower);
        newCartItem.set("estimatedPrice", this.state.estimatedPrice);
        if (this.state.lithoCategory === "Single Photo") {
          newCartItem.set(
            "singlePhotoHolderType",
            this.state.singlePhotoHolderType
          );
        }
        newCartItem.set("User", user);
        newCartItem.set("status", "In Cart");
        await newCartItem.save().then(() => {
          console.log("Done");
          el.disabled = false;
          if (el.id === "addToCart") {
            el.innerHTML = `<i className="fas fa-cart-plus"></i> Add to Cart`;
          } else {
            el.innerHTML = `<i className="tim-icons icon-send"></i> Buy Now`;
          }
        });
        // .catch(
        //   alert(
        //     "Something went wrong. Please contact us for further assistance"
        //   )
        // );
        await this.setState({ addToCartState: "completed" });
      }
    } else {
      this.toggleModal("loginModal");
    }
  };

  onBuyNow = async (e) => {
    await this.onAddToCart(e);
    if (this.state.addToCartState === "completed") {
      this.props.history.push("/checkout");
    }
  };

  repBoxPathUpdate = () => {
    let firstPathPart = `/pics/repBox/`;
    let secondPathPart = ``;
    if (this.state.lithoCategory === "Single Photo") {
      secondPathPart = `singlePhoto/${this.state.lithoStyle}`;
    } else if (this.state.lithoCategory === "Cube") {
      secondPathPart = `cube/${this.state.numPhotos}`;
      // secondPathPart = `cube/5`;
    } else if (this.state.lithoCategory === "Keychain") {
      secondPathPart = `keychain/`;
    }
    // console.log(firstPathPart + secondPathPart);
    this.setState({ repBoxPath: firstPathPart + secondPathPart });
  };

  lights = () => {
    // if (this.state.lights === true) {
    // document.getElementById("#lightsOff").toggleClass("transparent");
    // }
    // if (this.state.lights) {
    //   document.getElementById("bottom").style.display = "none";
    // } else {
    // document.getElementById("bottom").style.display = "block";
    // }
    this.setState({ lights: !this.state.lights });
  };

  sizes = (arr) => {
    let sizes = [];
    for (const length of arr) {
      let smallerSide;
      let largerSide;
      if (this.state.lithoCategory === "Keychain") {
        smallerSide = length;
        largerSide = Math.round(smallerSide * this.state.aspectRatio, 0);
      } else {
        largerSide = length;
        smallerSide = Math.round(largerSide / this.state.aspectRatio, 0);
      }
      sizes.push(
        <NavItem key={length}>
          <NavLink
            className={classnames({
              "active show": this.state.lithoSize === length,
            })}
            onClick={(e) => this.toggleTabs(e, "lithoSize", length)}
            href="#"
          >
            {largerSide} x {smallerSide}
          </NavLink>
        </NavItem>
      );
    }
    return (
      <>
        <Label>Size (mm x mm)</Label>
        <Row style={{ marginLeft: 0 }}>
          <Nav className="nav-pills-info " pills>
            {sizes}
          </Nav>
        </Row>
      </>
    );
  };

  styles = (arr) => {
    let styles = [];
    for (const style of arr) {
      styles.push(
        <NavItem key={style}>
          <NavLink
            className={classnames({
              "active show": this.state.lithoStyle === style,
            })}
            onClick={(e) => this.toggleTabs(e, "lithoStyle", style)}
            href="#"
          >
            {style}
          </NavLink>
        </NavItem>
      );
    }
    return (
      <>
        <Label>Style</Label>
        <Row style={{ marginLeft: 0 }}>
          <Nav className="nav-pills-info " pills>
            {styles}
          </Nav>
        </Row>
      </>
    );
  };

  aspectRatios = (arr) => {
    let aspectRatios = [];
    for (const aspectRatio of arr) {
      aspectRatios.push(
        <NavItem key={aspectRatio}>
          <NavLink
            className={classnames({
              "active show":
                this.state.aspectRatio === aspectRatio[0] / aspectRatio[1],
            })}
            onClick={(e) =>
              this.toggleTabs(e, "aspectRatio", aspectRatio[0] / aspectRatio[1])
            }
            href="#"
          >
            {aspectRatio[0]} : {aspectRatio[1]}
          </NavLink>
        </NavItem>
      );
    }
    return (
      <>
        <Label>Aspect Ratio</Label>{" "}
        <button id="aspectRatio" className="btn btn-link">
          <i className="far fa-question-circle"></i>
        </button>
        <UncontrolledTooltip delay={0} placement="right" target="aspectRatio">
          <img
            src="/pics/aspectRatios/aspectRatio16-9.png"
            height="100px"
            // width="300"
            alt=""
          />
          <img
            src="/pics/aspectRatios/aspectRatio4-3.png"
            height="100px"
            // width="300"
            alt=""
          />
          <img
            src="/pics/aspectRatios/aspectRatio1-1.png"
            height="100px"
            // width="300"
            alt=""
          />
        </UncontrolledTooltip>
        <Row style={{ marginLeft: 0 }}>
          <Nav className="nav-pills-info " pills>
            {aspectRatios}
          </Nav>
        </Row>
      </>
    );
  };
  // recieves an array with each NavItem, state that needs to be set as String, name for label
  generic = (arr, state, name) => {
    let generic = [];
    for (const genericItem of arr) {
      generic.push(
        <NavItem key={genericItem}>
          <NavLink
            className={classnames({
              "active show": this.state[state] === genericItem,
            })}
            onClick={(e) => this.toggleTabs(e, `${state}`, genericItem)}
            href="#"
          >
            {genericItem}
          </NavLink>
        </NavItem>
      );
    }
    return (
      <>
        <Label className="text-capitalize">{name}</Label>
        <Row style={{ marginLeft: 0 }}>
          <Nav className="nav-pills-info " pills>
            {generic}
          </Nav>
        </Row>
      </>
    );
  };

  buttons = () => {
    let buttons = [];
    for (let i = 1; i <= this.state.numPhotos; i++) {
      let btnText;
      if (this.state[`photo${i}`]) {
        btnText = "Uploaded";
      } else {
        btnText = `Upload Photo ${i}`;
      }
      buttons.push(
        <Button
          key={`${i}`}
          className="btn btn-simple"
          color="info"
          onClick={() => {
            if (this.state[`photo${i}`] !== null) {
              this.setState({ photo: i, src: this.state[`photo${i}`] });
            } else {
              this.setState({ photo: i, src: null });
            }
            this.toggleModal("lithoModal");
          }}
        >
          <i className="tim-icons icon-cloud-upload-94"></i> {btnText}
        </Button>
      );
    }
    return (
      <>
        <Row style={{ marginLeft: 0 }}>
          <Label>Photo</Label>
        </Row>
        <div>{buttons}</div>
      </>
    );
  };

  render() {
    return (
      <>
        <IndexNavbar
          getUserInfo={this.getUserInfo}
          loginModal={this.state.loginModal}
          signUpModal={this.state.signUpModal}
          toggleModal={this.toggleModal}
        />

        <div className="wrapper">
          <div className="page-header">
            <img
              alt="..."
              className="dots"
              src={require("assets/img/dots.png")}
            />
            <img
              alt="..."
              className="path"
              src={require("assets/img/path4.png")}
            />
            <Container
              className="align-items-center"
              style={{ paddingTop: "110px" }}
            >
              <h3 className="text-center">Customise your gift</h3>
              <Row className="h-100">
                <Col className="mx-auto py-auto" lg="5">
                  {/* <h4 className="text-center">Lights</h4> */}
                  <div className="text-center">
                    <Switch
                      defaultValue={true}
                      id="lightSwitch"
                      onText={`Lights\nOn!`}
                      offText={`Lights\nOff`}
                      value={this.state.lights}
                      bsSize="large"
                      style={{ verticalAlign: "middle" }}
                      onChange={this.lights}
                    />
                  </div>
                  <div id="repBox" className="mt-4">
                    <img src={loadingAnimation} alt="" />
                    <img
                      alt="..."
                      id="bottom"
                      className="img-center img-fluid "
                      src={`${this.state.repBoxPath}/Lights_on.jpg`}
                      srcSet={[
                        `${this.state.repBoxPath}/Lights_on.jpg-xsmall.jpg 400w`,
                        `${this.state.repBoxPath}/Lights_on.jpg-small.jpg 600w`,
                        `${this.state.repBoxPath}/Lights_on.jpg-medium.jpg 1000w`,
                        // `${this.state.repBoxPath}/Lights_on.jpg-large.jpg 1200w`,
                        // `${this.state.repBoxPath}/Lights_on.jpg-xlarge.jpg 1600w`
                      ]}
                      style={{ display: "none" }}
                    />
                    <img
                      alt="..."
                      id="top"
                      className={
                        this.state.lights
                          ? "transparent img-center img-fluid "
                          : "img-center img-fluid "
                      }
                      src={`${this.state.repBoxPath}/Lights_off.jpg`}
                      srcSet={[
                        `${this.state.repBoxPath}/Lights_off.jpg-xsmall.jpg 400w`,
                        `${this.state.repBoxPath}/Lights_off.jpg-small.jpg 600w`,
                        `${this.state.repBoxPath}/Lights_off.jpg-medium.jpg 1000w`,
                        // `${this.state.repBoxPath}/Lights_off.jpg-large.jpg 1200w`,
                        // `${this.state.repBoxPath}/Lights_off.jpg-xlarge.jpg 1600w`
                      ]}
                      importance="high"
                      style={{ display: "none" }}
                    />
                  </div>
                  <div className="tips">
                    <h4>Tips:</h4>
                    <ul>
                      <li>
                        Please upload high quality photos for best results
                      </li>
                      <li>Maximise the area of faces in the selection box</li>
                      <li>
                        Choose larger size in the option if there are multiple
                        people in your photo. Otherwise the faces may not appear
                        accurate.
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col lg="7">
                  <Row>
                    <Nav className="nav-tabs-info" role="tablist" tabs>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.lithoCategory === "Single Photo",
                          })}
                          onClick={(e) => {
                            this.toggleTabs(e, "lithoCategory", "Single Photo");
                            this.setState({
                              numPhotos: 1,
                              lithoSize: 75,
                              lithoStyle: "Plane",
                              aspectRatio: 16 / 9,
                              singlePhotoHolderType: "Minimal",
                            });
                          }}
                          href="#"
                        >
                          Single Photo
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.lithoCategory === "Keychain",
                          })}
                          onClick={(e) => {
                            this.toggleTabs(e, "lithoCategory", "Keychain");
                            this.setState({
                              numPhotos: 1,
                              lithoSize: 30,
                              aspectRatio: 16 / 9,
                            });
                          }}
                          href="#"
                        >
                          Keychain
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.lithoCategory === "Cube",
                          })}
                          onClick={(e) => {
                            this.toggleTabs(e, "lithoCategory", "Cube");
                            this.setState({
                              numPhotos: 4,
                              lithoSize: 75,
                              aspectRatio: 1,
                            });
                          }}
                          href="#"
                        >
                          Cube
                        </NavLink>
                      </NavItem>
                      {/* <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.lithoCategory === "Cylinder"
                          })}
                          onClick={e => {
                            this.toggleTabs(e, "lithoCategory", "Cylinder");
                            this.setState({
                              lithoSize: 100,
                              numPhotos: 1,
                              aspectRatio: 4
                            });
                          }}
                          href="#"
                        >
                          Cylinder
                        </NavLink>
                      </NavItem> */}

                      {/* <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.lithoCategory === "Holder"
                          })}
                          onClick={e => {
                            this.toggleTabs(e, "lithoCategory", "Holder");
                            this.state.cylinderMode === "Panorama"
                              ? this.setState({ numPhotos: 1, lithoSize: 75 })
                              : this.setState({
                                  numPhotos: 6,
                                  lithoSize: 75,
                                  holderStyle: "Plane",
                                  aspectRatio: 16 / 9,
                                  singlePhotoHolderType: "Minimal"
                                });
                          }}
                          href="#"
                        >
                          Holder
                        </NavLink>
                      </NavItem> */}
                    </Nav>
                    <TabContent
                      className="tab-space litho-tab"
                      activeTab={this.state.lithoCategory}
                    >
                      {/* Single Photo Section */}

                      <TabPane tabId="Single Photo">
                        {this.sizes([75, 100, 125, 150])}
                        {this.styles(["Plane", "Curved"])}
                        {this.aspectRatios([
                          [16, 9],
                          [4, 3],
                          [1, 1],
                        ])}
                        {this.generic(
                          ["Horizontal", "Vertical"],
                          "alignment",
                          "Alignment"
                        )}
                        {this.generic(
                          [
                            "None",
                            "Minimal",
                            "Complete",
                            // , "Rotatable"
                          ],
                          "singlePhotoHolderType",
                          "Holder Style"
                        )}
                        {/* Next Phase */}
                        {/* {this.state.holderStyle !== "None" && (
                          this.generic(
                          [
                            "Plug",
                            "Batteries"
                          ],
                          "holderPower",
                          "Holder Power Supply"
                        )
                        )} */}
                        {this.buttons()}
                      </TabPane>

                      {/* Keychain Section */}

                      <TabPane tabId="Keychain">
                        {this.sizes([30, 35, 40])}

                        {this.aspectRatios([
                          [16, 9],
                          [4, 3],
                        ])}

                        {this.generic(
                          ["Horizontal", "Vertical"],
                          "alignment",
                          "Alignment"
                        )}

                        {this.buttons()}
                      </TabPane>

                      {/* Cube Section */}

                      <TabPane tabId="Cube">
                        {this.sizes([75, 100, 125])}

                        {this.aspectRatios([
                          [16, 9],
                          [4, 3],
                          [1, 1],
                        ])}

                        {this.generic([4, 5], "numPhotos", "Number of Photos")}

                        {/* Next Phase */}
                        {/* {this.generic(
                          ["Fixed", "Rotatable"],
                          "holderStyle",
                          "Holder Style"
                        )}

                        {this.state.holderStyle !== "None" &&
                          this.generic(
                            ["Plug", "Batteries"],
                            "holderPower",
                            "Holder Power Supply"
                          )} */}
                        {this.buttons()}
                      </TabPane>

                      {/* Cylinder Section */}
                      <TabPane tabId="Cylinder">
                        {this.sizes([100, 125, 150])}

                        {this.generic(
                          ["Panorama", "Multiple Photos"],
                          "cylinderMode",
                          "Cylinder Photo Style"
                        )}

                        {this.state.numPhotos === 1
                          ? this.aspectRatios([
                              [20, 1],
                              [10, 1],
                              [4, 1],
                            ])
                          : this.aspectRatios([
                              [16, 9],
                              [4, 3],
                              [1, 1],
                            ])}

                        {this.state.cylinderMode === "multiplePhotos" &&
                          this.generic(
                            [4, 5, 6],
                            "numPhotos",
                            "Number of Photos"
                          )}

                        {/* Next Phase */}
                        {/* {this.generic(
                          ["Fixed", "Rotatable"],
                          "holderStyle",
                          "Holder Style"
                        )}

                        {this.state.holderStyle !== "None" &&
                          this.generic(
                            ["Plug", "Batteries"],
                            "holderPower",
                            "Holder Power Supply"
                          )} */}

                        {this.buttons()}
                      </TabPane>

                      {/* Holder Section */}

                      <TabPane tabId="Holder">
                        {this.generic(
                          ["Single Photo", "Cube"],
                          "holderFor",
                          "Holder For"
                        )}

                        {this.aspectRatios([
                          [16, 9],
                          [4, 3],
                          [1, 1],
                        ])}

                        {this.state.holderFor === "Single Photo" ? (
                          <>
                            {this.sizes([75, 100, 125, 150])}

                            {this.generic(
                              ["Plane", "Curved"],
                              "lithoStyle",
                              "Lithophane Style"
                            )}

                            {this.aspectRatios([
                              [16, 9],
                              [4, 3],
                              [1, 1],
                            ])}

                            {this.generic(
                              ["Minimal", "Complete"],
                              "singlePhotoHolderType",
                              "Holder Style"
                            )}
                          </>
                        ) : (
                          <>
                            {this.sizes([75, 100, 125])}
                            {this.aspectRatios([
                              [16, 9],
                              [4, 3],
                              [1, 1],
                            ])}

                            {this.generic(
                              [4, 5],
                              "numPhotos",
                              "Number of Photos"
                            )}
                          </>
                        )}
                        {this.generic(
                          ["Fixed", "Rotatable"],
                          "holderType",
                          "Holder Type"
                        )}
                        {this.generic(
                          ["Plug", "Batteries"],
                          "holderPower",
                          "Power Supply"
                        )}
                      </TabPane>
                    </TabContent>
                  </Row>
                  <hr />
                  <div
                    id="warning"
                    className="alert alert-danger"
                    style={{ display: "None" }}
                  >
                    Please upload Photo(s):{" "}
                  </div>
                  <h3>
                    Estimated Price:{" "}
                    <span className="float-right" style={{ color: "info" }}>
                      <i className="fas fa-rupee-sign"></i>
                      {this.state.estimatedPrice}
                    </span>
                  </h3>

                  <hr />
                  <Button
                    className="btn btn-round mb-5"
                    color="warning"
                    size="lg"
                    type="button"
                    onClick={(e) => this.onAddToCart(e)}
                    id="addToCart"
                    name="addToCart"
                  >
                    <i className="fas fa-cart-plus"></i> Add to Cart
                  </Button>
                  <Button
                    className="btn btn-round mb-5"
                    color="success"
                    size="lg"
                    type="button"
                    onClick={(e) => this.onBuyNow(e)}
                    id="buyNow"
                    name="buyNow"
                  >
                    <i className="tim-icons icon-send"></i> Buy Now!
                  </Button>
                </Col>
              </Row>
              <Modal
                modalClassName="modal-black modal-xl modal-profile"
                isOpen={this.state.lithoModal}
                toggle={() => this.toggleModal("lithoModal")}
              >
                <div className="modal-header justify-content-center">
                  <button
                    className="close"
                    onClick={() => this.toggleModal("lithoModal")}
                  >
                    <i className="tim-icons icon-simple-remove text-white" />
                  </button>
                  <div className="text-muted text-center ml-auto mr-auto">
                    <h5 className="mb-0">
                      Select your photo and make it magical
                    </h5>
                  </div>
                </div>
                <div className="modal-body">
                  <div className="btn-wrapper text-center">
                    <Form onSubmit={this.submitProfilePicture}>
                      <Dropzone
                        onDrop={(acceptedFiles) =>
                          this.onFilesAccepted(acceptedFiles)
                        }
                        multiple={false}
                        accept="image/*"
                        autoQueue={false}
                        noClick
                        style={{ width: "100%" }}
                        // onChange={this.onChangeImage}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div
                              {...getRootProps({
                                className: "dropzone",
                                style: {
                                  flex: 1,
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  padding: "10px",
                                  borderWidth: "2px",
                                  borderRadius: "2px",
                                  borderColor: "#eeeeee",
                                  borderStyle: "dashed",
                                  backgroundColor: "rgba(255,255,255,0)",
                                  color: "rgba(255,255,255,0.8)",
                                  outline: "None",
                                  transition: "border .24s ease-in-out",
                                },
                              })}
                            >
                              <input {...getInputProps()} />
                              <div style={{ width: "100%" }}>
                                <Cropper
                                  ref={(cropper) => {
                                    this.cropper = cropper;
                                  }}
                                  src={this.state.src}
                                  style={{ height: 400, width: "100%" }}
                                  // Cropper.js options
                                  aspectRatio={
                                    this.state.alignment === "Vertical"
                                      ? 1 / this.state.aspectRatio
                                      : this.state.aspectRatio
                                  }
                                  guides={true}
                                  viewMode={1}
                                  dragMode={"move"}
                                  background={false}
                                  autoCropArea={1}
                                  highlight={false}
                                  modal={true}
                                  // cropBoxResizable={false}
                                />
                              </div>

                              <p>Drag 'n' drop a photo here</p>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                      {/* <Button ></Button> */}
                      <div
                        className="fileinput fileinput-new input-group"
                        data-provides="fileinput"
                        style={{ marginTop: "5px" }}
                      >
                        <div
                          className="form-control"
                          data-trigger="fileinput"
                          style={{ paddingTop: "6px" }}
                        >
                          <span className="fileinput-filename"></span>
                        </div>
                        <span className="input-group-append">
                          <span
                            className="input-group-text fileinput-exists"
                            data-dismiss="fileinput"
                            style={{
                              paddingBottom: "5px",
                              paddingTop: "5px",
                              paddingLeft: "5px",
                            }}
                          >
                            Remove
                          </span>

                          <span
                            className="input-group-text btn-file"
                            style={{
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              paddingLeft: "5px",
                            }}
                          >
                            <span className="fileinput-new">Manual Upload</span>
                            <span className="fileinput-exists">Change</span>
                            <input
                              type="file"
                              name="..."
                              onInput={(e) => {
                                // console.log(e.target.files);
                                this.onFilesAccepted(e.target.files);
                              }}
                            />
                          </span>
                        </span>
                      </div>
                    </Form>
                    <Button
                      className="btn-round float-right mt-3"
                      color="primary"
                      data-placement="right"
                      id="photoSubmit"
                      type="button"
                      onClick={this.submitPhoto}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </Modal>
            </Container>
          </div>
          <Footer />
        </div>
      </>
    );
  }
}

export default Lithophane;
