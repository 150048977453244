/*!

=========================================================
* BLK Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
import Parse from "parse";

import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";

import SignUpModal from "../../components/User/RegisterModal";
import LoginModal from "../../components/User/LoginModal";

class ComponentsNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      color: "navbar-transparent",
      loginModal: false,
      signUpModal: false,
      isLoggedIn: false,
      firstName: "",
      email: "",
      password: "",
      terms: false,
      warning: "none",
      rememberMe: true,
      cartCount: 0,
    };
  }
  componentDidMount() {
    window.addEventListener("scroll", this.changeColor);
    if (this.props.loginModal != null) {
      this.setState({ loginModal: this.props.loginModal });
    }
    this.getUserInfo();
    this.getCartCount();
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.changeColor);
  }
  // componentWillReceiveProps = ({ loginModal }) => {
  //   this.setState({ loginModal });
  // };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.loginModal !== this.props.loginModal) {
      this.setState({ loginModal: this.props.loginModal });
    }
    if (prevProps.signUpModal !== this.props.signUpModal) {
      this.setState({ signUpModal: this.props.signUpModal });
    }
  }
  changeColor = () => {
    if (
      document.documentElement.scrollTop > 99 ||
      document.body.scrollTop > 99
    ) {
      this.setState({
        color: "bg-info",
      });
    } else if (
      document.documentElement.scrollTop < 100 ||
      document.body.scrollTop < 100
    ) {
      this.setState({
        color: "navbar-transparent",
      });
    }
  };
  toggleCollapse = () => {
    document.documentElement.classList.toggle("nav-open");
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };
  onCollapseExiting = () => {
    this.setState({
      collapseOut: "collapsing-out",
    });
  };
  onCollapseExited = () => {
    this.setState({
      collapseOut: "",
    });
  };
  scrollToDownload = () => {
    document
      .getElementById("download-section")
      .scrollIntoView({ behavior: "smooth" });
  };
  // Added by me
  getUserInfo = () => {
    if (Parse.User.current()) {
      let user = Parse.User.current();
      this.setState({ isLoggedIn: true, firstName: user.get("firstName") });
    }
  };
  getCartCount = async () => {
    const Items = Parse.Object.extend("Items");
    const ItemsQuery = new Parse.Query(Items);
    ItemsQuery.equalTo("User", Parse.User.current());
    ItemsQuery.equalTo("status", "In Cart");
    let cartItemsQuery = await ItemsQuery.count();
    this.setState({ cartCount: cartItemsQuery });
  };
  toggleModal = (modalState) => {
    if (this.props.toggleModal != null) {
      this.props.toggleModal(modalState);
    } else {
      this.setState({
        [modalState]: !this.state[modalState],
      });
    }
  };
  propsUserInfo = () => {
    this.props.getUserInfo != null
      ? this.props.getUserInfo()
      : console.log("No modal props provided");
  };

  handleLogOut = async () => {
    await Parse.User.logOut().then(() => {
      this.setState({
        isLoggedIn: false,
        firstName: "",
        email: "",
        password: "",
      });
      // this.props.getUserInfo();
      this.propsUserInfo();
    });
  };
  render(user) {
    return (
      <Navbar
        className={"fixed-top " + this.state.color}
        color-on-scroll="100"
        expand="lg"
        style={{ paddingTop: "0px", paddingBottom: "0px" }}
      >
        <Container>
          <div className="navbar-translate">
            <NavbarBrand
              data-placement="bottom"
              to="/"
              rel="noopener noreferrer"
              // title="Designed and Coded by Creative Tim"
              tag={Link}
            >
              <img src="/newLogo.png" alt="Gift Wizards Logo" width="40px" />{" "}
              <span>Gift Wizards </span>
              We deliver magic!
            </NavbarBrand>
            <button
              aria-expanded={this.state.collapseOpen}
              className="navbar-toggler navbar-toggler"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse
            className={"justify-content-end " + this.state.collapseOut}
            navbar
            isOpen={this.state.collapseOpen}
            onExiting={this.onCollapseExiting}
            onExited={this.onCollapseExited}
          >
            <div className="navbar-collapse-header">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <a href="/" onClick={(e) => e.preventDefault()}>
                    Gift Wizards
                  </a>
                </Col>
                <Col className="collapse-close text-right" xs="6">
                  <button
                    aria-expanded={this.state.collapseOpen}
                    className="navbar-toggler"
                    onClick={this.toggleCollapse}
                  >
                    <i className="tim-icons icon-simple-remove" />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav navbar>
              <NavItem className="p-0">
                <NavLink
                  data-placement="bottom"
                  href="https://twitter.com/gift_wizards"
                  rel="noopener noreferrer"
                  target="_blank"
                  title="Follow us on Twitter"
                >
                  <i className="fab fa-twitter fa-lg" />
                  <p className="d-lg-none d-xl-none">Twitter</p>
                </NavLink>
              </NavItem>
              <NavItem className="p-0">
                <NavLink
                  data-placement="bottom"
                  href="https://www.facebook.com/GiftWizards.in"
                  rel="noopener noreferrer"
                  target="_blank"
                  title="Like us on Facebook"
                >
                  <i className="fab fa-facebook-square fa-lg" />
                  <p className="d-lg-none d-xl-none">Facebook</p>
                </NavLink>
              </NavItem>
              <NavItem className="p-0">
                <NavLink
                  data-placement="bottom"
                  href="https://www.instagram.com/wizards_gift"
                  rel="noopener noreferrer"
                  target="_blank"
                  title="Follow us on Instagram"
                >
                  <i className="fab fa-instagram fa-lg" />
                  <p className="d-lg-none d-xl-none">Instagram</p>
                </NavLink>
              </NavItem>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  href="#"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  {/* <i className="fa fa-cogs d-lg-none d-xl-none" /> */}
                  <i className="tim-icons icon-single-02" />
                  Hello {this.state.isLoggedIn ? this.state.firstName : "Guest"}
                </DropdownToggle>
                <DropdownMenu className="dropdown-with-icons">
                  {!this.state.isLoggedIn ? (
                    <>
                      <DropdownItem
                        tag={Link}
                        onClick={() => this.toggleModal("loginModal")}
                        to="#"
                      >
                        <i className="fas fa-user" />
                        Login
                      </DropdownItem>
                      <DropdownItem
                        tag={Link}
                        onClick={() => this.toggleModal("signUpModal")}
                        to="#"
                      >
                        <i className="fas fa-user-plus" />
                        Register
                      </DropdownItem>
                    </>
                  ) : (
                    <>
                      <DropdownItem tag={Link} to="/profile">
                        <i className="tim-icons icon-badge" />
                        Profile
                      </DropdownItem>
                      <DropdownItem tag={Link} to="/my-orders">
                        <i className="tim-icons icon-bag-16" />
                        My Orders
                      </DropdownItem>
                      <DropdownItem
                        tag={Link}
                        to="#"
                        onClick={this.handleLogOut}
                      >
                        <i className="tim-icons icon-single-02" />
                        Logout
                      </DropdownItem>
                    </>
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem className="p-0">
                <NavLink data-placement="bottom" tag={Link} to="/cart">
                  <i className="tim-icons icon-cart"></i>
                  {/* <Badge
                    size="sm"
                    style={{
                      // backgroundColor: "rgba(0,0,0,0)",
                      // color: "#f4f5f7",
                      padding: "0px 2px",
                      position: "absolute",
                      left: "12px",
                      top: "2px",
                      zIndex: 2
                    }}
                  >
                    {this.state.cartCount}{" "}
                  </Badge>{" "} */}
                  Cart
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
          {/* Start Login Form Modal */}
          <LoginModal
            loginModal={this.state.loginModal}
            toggleModal={this.toggleModal}
            getUserInfo={this.getUserInfo}
            propsUserInfo={this.propsUserInfo}
          />
          {/* End Login Form Modal */}
          {/* Start Sign Up Form Modal */}
          <SignUpModal
            signUpModal={this.state.signUpModal}
            toggleModal={this.toggleModal}
            getUserInfo={this.getUserInfo}
            propsUserInfo={this.propsUserInfo}
          />

          {/* End Sign Up Form Modal */}
        </Container>
      </Navbar>
    );
  }
}

export default ComponentsNavbar;
