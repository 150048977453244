/*!

=========================================================
* BLK Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// reactstrap components
import { Container } from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";

let ps = null;

class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      loginModal: false,
      signUpModal: false
    };
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    document.body.classList.toggle("profile-page");
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
    document.body.classList.toggle("profile-page");
  }

  render() {
    return (
      <>
        <IndexNavbar
          getUserInfo={this.getUserInfo}
          loginModal={this.state.loginModal}
          signUpModal={this.state.signUpModal}
          toggleModal={this.toggleModal}
        />
        <div className="wrapper">
          <div className="page-header">
            <img
              alt="..."
              className="dots"
              src={require("assets/img/dots.png")}
            />
            <img
              alt="..."
              className="path"
              src={require("assets/img/path4.png")}
            />
            <Container className="align-items-center">
              <h1>Welcome to Gift Wizards</h1>
              <p>
                Here at Gift Wizards, we know just how much occasions like
                birthdays and weddings of your loved friends and family can mean
                to you. We also know how much of a hassle it can be to find a
                unique gift that is not just another piece going into the store
                room. With that in mind, we make novel and customised gifts.
                Gifts that are gauranteed to make people go WOW!
              </p>
              <br />
              <p>
                We use modern and advanced 3D printing to turn your photos into
                novel things which appear meaningless at first glance but show
                photos when lighted from behind.
              </p>
              <br />
              <p>
                Our Customizer helps you choose exactly what you want and
                customize to make it perfect. We are working hard on bringing
                more amazing features to this and would love for you to follow
                us. You can find us at{" "}
                <Link to="https://www.facebook.com/GiftWizards.in">
                  Twitter
                </Link>
                , <Link to="https://twitter.com/gift_wizards">Facebook</Link>{" "}
                and{" "}
                <Link to="https://www.instagram.com/wizards_gift">
                  Instagram
                </Link>{" "}
                .
              </p>
              <br />
              <p>
                Also, please do not hesitate to{" "}
                <Link to="/contact-us">contact us</Link> in case of any query of
                feedback. We take your feedback very seriously and strive to
                answer your query ASAP.
              </p>
              <br />
              <br />
              <h4>
                Sincerely, <br />
                Ravi Kiran P,
                <br />
                Founder
              </h4>
              <br />
              <br />
              <br />
              <br />
            </Container>
          </div>
          <Footer />
        </div>
      </>
    );
  }
}

export default PrivacyPolicy;
