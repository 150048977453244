/*!

=========================================================
* BLK Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import Parse from "parse";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";

let ps = null;

class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      message: "",
      validated: "",
      messageSent: false,
      hasChanged: false,
      isLoggedIn: false,
      loginModal: false,
      signUpModal: false
    };
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    document.body.classList.toggle("profile-page");
    this.getUserInfo();
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
    document.body.classList.toggle("profile-page");
  }

  toggleTabs = (e, stateName, index) => {
    e.preventDefault();
    this.setState({
      [stateName]: index
    });
  };
  //Mine
  handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
    this.setState({ hasChanged: true });
  };
  validate = () => {
    let { name, phone, message, email } = this.state;
    if (name === "") {
      document.getElementById("name").style.borderColor = "crimson";
      this.setState({ validated: false });
    } else {
      this.setState({ validated: true });
    }

    if (phone === "") {
      document.getElementById("phone").style.borderColor = "crimson";
      this.setState({ validated: false });
    } else {
      this.setState({ validated: true });
    }
    if (email === "") {
      document.getElementById("email").style.borderColor = "crimson";
      this.setState({ validated: false });
    } else {
      this.setState({ validated: true });
    }
    if (message === "") {
      document.getElementById("message").style.borderColor = "crimson";
      this.setState({ validated: false });
    } else {
      this.setState({ validated: true });
    }
  };
  handleSubmit = async () => {
    await this.validate();
    if (this.state.validated) {
      let newMessage = new Parse.Object("Messages");
      newMessage.set("name", this.state.firstName);
      newMessage.set("phone", this.state.phone);
      newMessage.set("message", this.state.state);
      newMessage.set("email", this.state.pincode);
      newMessage.save().then(this.setState({ messageSent: true }));
    }
  };
  getUserInfo = () => {
    let user = Parse.User.current();
    if (user) {
      this.setState({
        isLoggedIn: true,
        name: user.get("firstName"),
        email: user.get("email")
      });
      if (user.get("phone")) {
        this.setState({ phone: user.get("phone") });
      }
    } else {
      this.setState({ isLoggedIn: false });
    }
  };

  toggleModal = modalState => {
    this.setState({
      [modalState]: !this.state[modalState]
    });
  };

  render() {
    return (
      <>
        <IndexNavbar
          getUserInfo={this.getUserInfo}
          loginModal={this.state.loginModal}
          signUpModal={this.state.signUpModal}
          toggleModal={this.toggleModal}
        />
        <div className="wrapper">
          {!this.state.messageSent ? (
            <>
              <div className="page-header">
                <img
                  alt="..."
                  className="dots"
                  src={require("assets/img/dots.png")}
                />
                <img
                  alt="..."
                  className="path"
                  src={require("assets/img/path4.png")}
                />
                <Container className="align-items-center">
                  <Row>
                    <Col md="6">
                      <Form>
                        <Row>
                          <Col md="10" className="mx-auto">
                            <FormGroup>
                              <label>Your Name</label>
                              <Input
                                type="text"
                                name="name"
                                id="name"
                                onChange={this.handleInputChange}
                                value={this.state.name}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          {/* <Col md="6"> */}
                          <Col md="10" className="mx-auto">
                            <FormGroup>
                              <label>Email address</label>
                              <Input
                                value={this.state.email}
                                type="email"
                                id="email"
                                name="email"
                                onChange={this.handleInputChange}
                              />
                            </FormGroup>
                          </Col>

                          {/* <Col md="6"> */}
                        </Row>
                        <Row>
                          <Col md="10" className="mx-auto">
                            <FormGroup>
                              <label>Phone</label>
                              <Input
                                type="text"
                                id="phone"
                                name="phone"
                                onChange={this.handleInputChange}
                                value={this.state.phone}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          {/* <Col md="10"> */}
                          <Col md="10" className="mx-auto">
                            <FormGroup>
                              <label>Message</label>
                              <Input
                                type="textarea"
                                id="message"
                                name="message"
                                placeholder="Your message here"
                                onChange={this.handleInputChange}
                                value={this.state.message}
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Button
                          className="btn-round float-right mb-3"
                          color="primary"
                          data-placement="right"
                          id="updateProfile"
                          type="button"
                          onClick={this.handleSubmit}
                          disabled={
                            !this.state.hasChanged && !this.state.validated
                          }
                        >
                          Send
                        </Button>
                      </Form>
                    </Col>
                    <Col md="6" className="my-auto">
                      Alternatively you may write to us at
                      support[at]giftwizards.in
                    </Col>
                  </Row>
                </Container>
              </div>
            </>
          ) : (
            <>
              <div className="page-header">
                <img
                  alt="..."
                  className="dots"
                  src={require("assets/img/dots.png")}
                />
                <img
                  alt="..."
                  className="path"
                  src={require("assets/img/path4.png")}
                />
                <Container className="align-items-center">
                  <div className="text-center">
                    <h1>
                      Thank you very much. Your message has been sent. We will
                      get back to you ASAP
                    </h1>
                  </div>
                </Container>
              </div>
            </>
          )}
          <Footer />
        </div>
      </>
    );
  }
}

export default ContactUs;
