import React, { Component } from "react";

class States extends Component {
  render() {
    return (
      <>
        {" "}
        <option
          style={{
            color: "rgba(255, 255, 255, 0.8)",
            background: "#171941"
          }}
          value=""
        >
          ------------Select State------------
        </option>
        <option
          style={{
            color: "rgba(255, 255, 255, 0.8)",
            background: "#171941"
          }}
          value="Andaman and Nicobar Islands"
        >
          Andaman and Nicobar Islands
        </option>
        <option
          style={{
            color: "rgba(255, 255, 255, 0.8)",
            background: "#171941"
          }}
          value="Andhra Pradesh"
        >
          Andhra Pradesh
        </option>
        <option
          style={{
            color: "rgba(255, 255, 255, 0.8)",
            background: "#171941"
          }}
          value="Arunachal Pradesh"
        >
          Arunachal Pradesh
        </option>
        <option
          style={{
            color: "rgba(255, 255, 255, 0.8)",
            background: "#171941"
          }}
          value="Assam"
        >
          Assam
        </option>
        <option
          style={{
            color: "rgba(255, 255, 255, 0.8)",
            background: "#171941"
          }}
          value="Bihar"
        >
          Bihar
        </option>
        <option
          style={{
            color: "rgba(255, 255, 255, 0.8)",
            background: "#171941"
          }}
          value="Chandigarh"
        >
          Chandigarh
        </option>
        <option
          style={{
            color: "rgba(255, 255, 255, 0.8)",
            background: "#171941"
          }}
          value="Chhattisgarh"
        >
          Chhattisgarh
        </option>
        <option
          style={{
            color: "rgba(255, 255, 255, 0.8)",
            background: "#171941"
          }}
          value="Dadra and Nagar Haveli"
        >
          Dadra and Nagar Haveli
        </option>
        <option
          style={{
            color: "rgba(255, 255, 255, 0.8)",
            background: "#171941"
          }}
          value="Daman and Diu"
        >
          Daman and Diu
        </option>
        <option
          style={{
            color: "rgba(255, 255, 255, 0.8)",
            background: "#171941"
          }}
          value="Delhi"
        >
          Delhi
        </option>
        <option
          style={{
            color: "rgba(255, 255, 255, 0.8)",
            background: "#171941"
          }}
          value="Goa"
        >
          Goa
        </option>
        <option
          style={{
            color: "rgba(255, 255, 255, 0.8)",
            background: "#171941"
          }}
          value="Gujarat"
        >
          Gujarat
        </option>
        <option
          style={{
            color: "rgba(255, 255, 255, 0.8)",
            background: "#171941"
          }}
          value="Haryana"
        >
          Haryana
        </option>
        <option
          style={{
            color: "rgba(255, 255, 255, 0.8)",
            background: "#171941"
          }}
          value="Himachal Pradesh"
        >
          Himachal Pradesh
        </option>
        <option
          style={{
            color: "rgba(255, 255, 255, 0.8)",
            background: "#171941"
          }}
          value="Jammu and Kashmir"
        >
          Jammu and Kashmir
        </option>
        <option
          style={{
            color: "rgba(255, 255, 255, 0.8)",
            background: "#171941"
          }}
          value="Jharkhand"
        >
          Jharkhand
        </option>
        <option
          style={{
            color: "rgba(255, 255, 255, 0.8)",
            background: "#171941"
          }}
          value="Karnataka"
        >
          Karnataka
        </option>
        <option
          style={{
            color: "rgba(255, 255, 255, 0.8)",
            background: "#171941"
          }}
          value="Kerala"
        >
          Kerala
        </option>
        <option
          style={{
            color: "rgba(255, 255, 255, 0.8)",
            background: "#171941"
          }}
          value="Ladakh"
        >
          Ladakh
        </option>
        <option
          style={{
            color: "rgba(255, 255, 255, 0.8)",
            background: "#171941"
          }}
          value="Lakshadweep"
        >
          Lakshadweep
        </option>
        <option
          style={{
            color: "rgba(255, 255, 255, 0.8)",
            background: "#171941"
          }}
          value="Madhya Pradesh"
        >
          Madhya Pradesh
        </option>
        <option
          style={{
            color: "rgba(255, 255, 255, 0.8)",
            background: "#171941"
          }}
          value="Maharashtra"
        >
          Maharashtra
        </option>
        <option
          style={{
            color: "rgba(255, 255, 255, 0.8)",
            background: "#171941"
          }}
          value="Manipur"
        >
          Manipur
        </option>
        <option
          style={{
            color: "rgba(255, 255, 255, 0.8)",
            background: "#171941"
          }}
          value="Meghalaya"
        >
          Meghalaya
        </option>
        <option
          style={{
            color: "rgba(255, 255, 255, 0.8)",
            background: "#171941"
          }}
          value="Mizoram"
        >
          Mizoram
        </option>
        <option
          style={{
            color: "rgba(255, 255, 255, 0.8)",
            background: "#171941"
          }}
          value="Nagaland"
        >
          Nagaland
        </option>
        <option
          style={{
            color: "rgba(255, 255, 255, 0.8)",
            background: "#171941"
          }}
          value="Orissa"
        >
          Orissa
        </option>
        <option
          style={{
            color: "rgba(255, 255, 255, 0.8)",
            background: "#171941"
          }}
          value="Pondicherry"
        >
          Pondicherry
        </option>
        <option
          style={{
            color: "rgba(255, 255, 255, 0.8)",
            background: "#171941"
          }}
          value="Punjab"
        >
          Punjab
        </option>
        <option
          style={{
            color: "rgba(255, 255, 255, 0.8)",
            background: "#171941"
          }}
          value="Rajasthan"
        >
          Rajasthan
        </option>
        <option
          style={{
            color: "rgba(255, 255, 255, 0.8)",
            background: "#171941"
          }}
          value="Sikkim"
        >
          Sikkim
        </option>
        <option
          style={{
            color: "rgba(255, 255, 255, 0.8)",
            background: "#171941"
          }}
          value="Tamil Nadu"
        >
          Tamil Nadu
        </option>
        <option
          style={{
            color: "rgba(255, 255, 255, 0.8)",
            background: "#171941"
          }}
          value="Telangana"
        >
          Telangana
        </option>
        <option
          style={{
            color: "rgba(255, 255, 255, 0.8)",
            background: "#171941"
          }}
          value="Tripura"
        >
          Tripura
        </option>
        <option
          style={{
            color: "rgba(255, 255, 255, 0.8)",
            background: "#171941"
          }}
          value="Uttaranchal"
        >
          Uttaranchal
        </option>
        <option
          style={{
            color: "rgba(255, 255, 255, 0.8)",
            background: "#171941"
          }}
          value="Uttar Pradesh"
        >
          Uttar Pradesh
        </option>
        <option
          style={{
            color: "rgba(255, 255, 255, 0.8)",
            background: "#171941"
          }}
          value="West Bengal"
        >
          West Bengal
        </option>
      </>
    );
  }
}

export default States;
