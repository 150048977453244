/*!

=========================================================
* BLK Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Parse from "parse";
import * as serviceWorker from "./serviceWorker";

import "./assets/css/nucleo-icons.css";
// import "./assets/scss/blk-design-system-react.scss";
import "assets/css/blk-design-system-react.min.css";
import "assets/js/loader.js";

import Index from "./views/Index.jsx";
import Profile from "./views/user/Profile.jsx";
import Lithophane from "./views/products/Lithophane";
import Cart from "./views/orders/Cart";
import MyOrders from "./views/orders/MyOrders";
import Checkout from "./views/orders/Checkout";
import PrivacyPolicy from "./views/misc/privacy-policy";
import Terms from "./views/misc/terms-and-conditions";
import ContactUs from "./views/misc/contact-us";
import AboutUs from "./views/misc/about-us";
import Three from "views/test/3DViewer";
// import ComingSoon from "./views/misc/coming-soon";

Parse.serverURL = "https://giftwizards-parse.rover.giftwizards.in/parse"; // "https://api.giftwizards.in/parse"; // process.env.serverURL || "http://localhost:1337/parse";
// "https://giftwizards-parse.giftwizards.thriftyengineer.com/parse";
Parse.initialize("giftwizards007");

ReactDOM.render(
  // <BrowserRouter basename="/gift-wizards">
  <BrowserRouter>
    <Switch>
      <Route path="/cart" render={(props) => <Cart {...props} />} />
      <Route path="/my-orders" render={(props) => <MyOrders {...props} />} />
      <Route path="/checkout" render={(props) => <Checkout {...props} />} />
      <Route
        path="/products/lithophane"
        render={(props) => <Lithophane {...props} />}
      />
      <Route path="/profile" render={(props) => <Profile {...props} />} />

      <Route
        path="/privacy-policy"
        render={(props) => <PrivacyPolicy {...props} />}
      />
      <Route
        path="/terms-and-conditions"
        render={(props) => <Terms {...props} />}
      />
      <Route path="/contact-us" render={(props) => <ContactUs {...props} />} />
      <Route path="/about-us" render={(props) => <AboutUs {...props} />} />
      <Route path="/three" render={(props) => <Three {...props} />} />

      <Route path="/" render={(props) => <Index {...props} />} />
      {/* <Route path="/" render={props => <ComingSoon {...props} />} /> */}
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
serviceWorker.register();
