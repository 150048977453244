import React, { Component } from "react";
import Parse from "parse";
import classnames from "classnames";
import {
  Button,
  Modal,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  FormGroup,
  Row
} from "reactstrap";

class LoginModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      color: "navbar-transparent",
      loginModal: false,
      signUpModal: false,
      isLoggedIn: false,
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      terms: false,
      warning: "none",
      rememberMe: true,
      passwordResetModal: false,
      resetMailSent: false
    };
  }
  componentWillReceiveProps({ loginModal }) {
    this.setState({ loginModal });
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };
  handleLogin = () => {
    Parse.User.logIn(this.state.email, this.state.password)
      .then(() => {
        this.props.getUserInfo();
        this.props.propsUserInfo();
        this.props.toggleModal("loginModal");
      })
      .catch(error => {
        console.log(error.message);
        document.getElementById("loginWarning").innerHTML = error.message;
        document.getElementById("loginWarning").style.cssText =
          "display: block;color: rgba(255,255,255,.6);size: .8rem";
      });
  };
  passwordResetModal = () => {
    this.setState({
      passwordResetModal: !this.state.passwordResetModal,
      resetMailSent: false
    });
  };
  passwordReset = () => {
    Parse.User.requestPasswordReset(this.state.email)
      .then(() => {
        this.setState({ resetMailSent: true });
      })
      .catch(e => {
        console.log(e);
        document.getElementById("loginWarning").innerHTML = e.message;
        document.getElementById("loginWarning").style.cssText =
          "display: block;color: rgba(255,255,255,.6);size: .8rem";
      });
  };
  render() {
    return (
      <>
        {/* Start Login Form Modal */}
        <Modal
          modalClassName="modal-black"
          isOpen={this.state.loginModal}
          toggle={() => this.props.toggleModal("loginModal")}
        >
          <div className="modal-header justify-content-center">
            <button
              className="close"
              onClick={() => this.props.toggleModal("loginModal")}
            >
              <i className="tim-icons icon-simple-remove text-white" />
            </button>
            <h3 className="mb-0">Sign in</h3>
            {/* <div className="text-muted text-center ml-auto mr-auto">
              <h3 className="mb-0">Sign in with</h3>
            </div> */}
          </div>
          <div className="modal-body">
            {/* <div className="btn-wrapper text-center">
              <Button
                className="btn-neutral btn-icon"
                color="default"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <img alt="..." src={require("assets/img/github.svg")} />
              </Button>
              <Button
                className="btn-neutral btn-icon"
                color="default"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <img alt="..." src={require("assets/img/google.svg")} />
              </Button>
            </div> */}
            {/* <div className="text-center text-muted mb-4 mt-3">
              <small>Or sign in with credentials</small>
            </div> */}
            {this.state.passwordResetModal ? (
              this.state.resetMailSent ? (
                <div className="p-2">
                  <h4>Please check your mail for the password reset link</h4>
                  <Row className="justify-content-center">
                    <Button
                      className="btn-link"
                      onClick={this.passwordResetModal}
                      color="primary"
                    >
                      Back to login
                    </Button>
                  </Row>
                </div>
              ) : (
                <Form className="justify-content-center">
                  <InputGroup
                    className={classnames("input-group-alternative", {
                      "input-group-focus": this.state.emailFocus
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-email-85" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      type="email"
                      onFocus={e => this.setState({ emailFocus: true })}
                      onBlur={e => this.setState({ emailFocus: false })}
                      name="email"
                      onChange={this.handleInputChange}
                      value={this.state.email}
                    />
                  </InputGroup>
                  <div
                    id="loginWarning"
                    className="text-center"
                    style={{ display: this.state.warning }}
                  >
                    Something went wrong
                  </div>
                  <div className="text-center">
                    <Button
                      color="primary"
                      className="justify-content-center"
                      onClick={this.passwordReset}
                    >
                      Reset password
                    </Button>
                  </div>
                  <Row className="justify-content-center">
                    <Button
                      className="btn-link"
                      onClick={this.passwordResetModal}
                      color="primary"
                    >
                      Login instead
                    </Button>
                  </Row>
                </Form>
              )
            ) : (
              <Form role="form" onSubmit={this.handleLogin}>
                <FormGroup className="mb-3">
                  <InputGroup
                    className={classnames("input-group-alternative", {
                      "input-group-focus": this.state.emailFocus
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-email-85" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      type="email"
                      onFocus={e => this.setState({ emailFocus: true })}
                      onBlur={e => this.setState({ emailFocus: false })}
                      name="email"
                      onChange={this.handleInputChange}
                      value={this.state.email}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup
                    className={classnames("input-group-alternative", {
                      "input-group-focus": this.state.passwordFocus
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-key-25" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Password"
                      type="password"
                      onFocus={e => this.setState({ passwordFocus: true })}
                      onBlur={e => this.setState({ passwordFocus: false })}
                      name="password"
                      onChange={this.handleInputChange}
                      value={this.state.password}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup check className="mt-3">
                  <Label check>
                    <Input
                      defaultChecked
                      type="checkbox"
                      name="rememberMe"
                      onChange={this.handleInputChange}
                    />
                    <span className="form-check-sign" />
                    Remember me!
                  </Label>
                </FormGroup>
                <div
                  id="loginWarning"
                  className="text-center"
                  style={{ display: this.state.warning }}
                >
                  Please agree to our terms and conditions to proceed!
                </div>
                <div className="text-center">
                  <Button
                    className="mt-2"
                    color="primary"
                    type="button"
                    onClick={this.handleLogin}
                  >
                    Sign in
                  </Button>
                  <Row className="justify-content-center">
                    <Button
                      className="btn-link"
                      onClick={this.passwordResetModal}
                      color="primary"
                    >
                      Forgot Password?
                    </Button>
                  </Row>
                </div>
              </Form>
            )}
          </div>
        </Modal>
        {/* End Login Form Modal */}
      </>
    );
  }
}

export default LoginModal;
