import React, { Component } from "react";
import Parse from "parse";
import { Link } from "react-router-dom";
import classnames from "classnames";
import {
  Button,
  Container,
  Row,
  Col,
  Modal,
  Label,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  UncontrolledCarousel,
} from "reactstrap";

import IndexNavbar from "components/Navbars/IndexNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";

class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      loginModal: false,
      signUpModal: false,
      photosModal: false,
      carouselItems: [],
      cartItems: [],
      refetch: false,
      plainTabs: "cart",
      savedForLater: [],
    };
  }
  componentDidMount() {
    this.getUserInfo();
    this.getCartItems();
    this.getSavedItems();
    document.body.classList.toggle("profile-page");
  }
  componentWillUnmount() {
    document.body.classList.toggle("profile-page");
  }
  toggleTabs = (e, stateName, index) => {
    e.preventDefault();
    this.setState({
      [stateName]: index,
    });
  };
  getUserInfo = async () => {
    let user = Parse.User.current();
    if (user) {
      this.setState({
        isLoggedIn: true,
      });
    } else {
      this.setState({ isLoggedIn: false });
    }
  };
  getCartItems = async () => {
    const Items = Parse.Object.extend("Items");
    const ItemsQuery = new Parse.Query(Items);
    ItemsQuery.equalTo("User", Parse.User.current());
    ItemsQuery.equalTo("status", "In Cart");
    let cartItemsQuery = await ItemsQuery.find();
    let cartItems = [];
    cartItemsQuery.length !== 0
      ? cartItemsQuery.forEach(async (item) => {
          const cartItemDetails = new Parse.Query(Items);
          let cartItem = await cartItemDetails.get(item.id);
          cartItem.repPath = await this.repPhotoPath(item);
          cartItems.push(cartItem);
          this.setState({ cartItems });
        })
      : this.setState({ cartItems });
  };
  getSavedItems = async () => {
    const Items = Parse.Object.extend("Items");
    const ItemsQuery = new Parse.Query(Items);
    ItemsQuery.equalTo("User", Parse.User.current());
    ItemsQuery.equalTo("status", "Saved for later");
    let savedItemsQuery = await ItemsQuery.find();
    let savedForLater = [];
    savedItemsQuery.length !== 0
      ? savedItemsQuery.forEach(async (item) => {
          const savedItemDetails = new Parse.Query(Items);
          let savedItem = await savedItemDetails.get(item.id);
          savedItem.repPath = await this.repPhotoPath(item);
          savedForLater.push(savedItem);
          this.setState({ savedForLater });
        })
      : this.setState({ savedForLater });
  };
  repPhotoPath = (item) => {
    let firstPathPart = `/pics/repBox/`;
    let secondPathPart = ``;
    if (item.attributes.lithoCategory === "Single Photo") {
      secondPathPart = `singlePhoto/${item.attributes.lithoStyle}`;
    } else if (item.attributes.lithoCategory === "Cube") {
      secondPathPart = `cube/${item.attributes.numPhotos}`;
      // secondPathPart = `cube/5`;
    } else if (item.attributes.lithoCategory === "Keychain") {
      secondPathPart = `keychain/`;
    }
    return `${firstPathPart}${secondPathPart}/Lights_on.jpg-xsmall.jpg`;
  };

  toggleModal = (modalState) => {
    this.setState({
      [modalState]: !this.state[modalState],
    });
  };
  viewPhotos = (order) => {
    this.toggleModal("photosModal");
    let carouselItems = [];
    for (let i = 1; i <= order.attributes.numPhotos; i++) {
      let photo = "photo" + i;
      let carouselObject = {
        src: order.attributes[photo]._url,
        altText: `Photo ${i}`,
        caption: `Photo ${i}`,
      };
      carouselItems.push(carouselObject);
      this.setState({ carouselItems });
    }
  };
  saveForLater = async (id) => {
    console.log(id);
    let Item = new Parse.Object.extend("Items");
    const ItemQuery = new Parse.Query(Item);
    let item = await ItemQuery.get(id);
    // console.log(item);
    item.set("status", "Saved for later");
    item.save().then(async () => {
      await this.setState({ refetch: true });
      this.getSavedItems();
      this.getCartItems();
    });
  };
  moveToCart = async (id) => {
    console.log(id);
    let Item = new Parse.Object.extend("Items");
    const ItemQuery = new Parse.Query(Item);
    let item = await ItemQuery.get(id);
    // console.log(item);
    item.set("status", "In Cart");
    item.save().then(async () => {
      await this.setState({ refetch: true });
      this.getCartItems();
      this.getSavedItems();
    });
  };
  deleteItem = async (item) => {
    let Item = new Parse.Object.extend("Items");
    const ItemQuery = new Parse.Query(Item);
    ItemQuery.get(item.id).then(async (result) => {
      result.destroy({});
      await this.setState({ refetch: true });
      this.getSavedItems();
      this.getCartItems();
    });
    // console.log(item.id);
  };
  render() {
    return (
      <>
        <IndexNavbar
          getUserInfo={this.getUserInfo}
          loginModal={this.state.loginModal}
          signUpModal={this.state.signUpModal}
          toggleModal={this.toggleModal}
        />
        <div className="wrapper">
          <div className="page-header">
            <img
              alt="..."
              className="dots"
              src={require("assets/img/dots.png")}
              style={{ zIndex: "-1" }}
            />
            <img
              alt="..."
              className="path"
              src={require("assets/img/path2.png")}
              style={{ zIndex: "-1" }}
            />
            <Container
              className="align-items-center"
              style={{ paddingTop: "110px", marginBottom: "30px" }}
            >
              {this.state.isLoggedIn ? (
                <>
                  <Card className="card-nav-tabs card-plain">
                    <CardHeader className="card-header-danger">
                      {/* colors: "header-primary", "header-info", "header-success", "header-warning", "header-danger" */}
                      <div className="nav-tabs-navigation">
                        <div className="nav-tabs-wrapper">
                          <Nav data-tabs="tabs" tabs>
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: this.state.plainTabs === "cart",
                                })}
                                onClick={(e) =>
                                  this.toggleTabs(e, "plainTabs", "cart")
                                }
                                href="#pablo"
                              >
                                Cart
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active:
                                    this.state.plainTabs === "savedForLater",
                                })}
                                onClick={(e) =>
                                  this.toggleTabs(
                                    e,
                                    "plainTabs",
                                    "savedForLater"
                                  )
                                }
                                href="#pablo"
                              >
                                Saved For Later
                              </NavLink>
                            </NavItem>
                          </Nav>
                        </div>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <TabContent
                        className="text-center"
                        activeTab={this.state.plainTabs}
                      >
                        <TabPane tabId="cart">
                          {this.state.cartItems.length !== 0 ? (
                            <>
                              {this.state.cartItems.map((item) => (
                                <Card key={item.id} style={{ opacity: ".8" }}>
                                  <CardHeader>
                                    <Label className="float-left">
                                      Order ID: {item.id}
                                    </Label>
                                    <span
                                      className="float-right "
                                      style={{
                                        color: "info",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <i className="fas fa-rupee-sign"></i>
                                      {item.attributes.estimatedPrice}
                                    </span>
                                  </CardHeader>
                                  <CardBody>
                                    <Row>
                                      <Col md="2">
                                        <img
                                          src={item.repPath}
                                          alt=""
                                          // width="400px"
                                          // height="300px"
                                        />
                                      </Col>
                                      <Col>
                                        <Row>
                                          <Col>
                                            <Row className="mt-3">
                                              Type:{" "}
                                              {item.attributes.lithoCategory}
                                            </Row>
                                            <Row className="mt-3">
                                              Size: {item.attributes.lithoSize}
                                            </Row>
                                            <Row className="mt-3">
                                              Number of Photos:{" "}
                                              {item.attributes.numPhotos}
                                            </Row>
                                          </Col>
                                          <Col>
                                            {item.attributes.lithoCategory ===
                                              "Single Photo" && (
                                              <>
                                                <Row className="mt-3">
                                                  Style:{" "}
                                                  {item.attributes.lithoStyle}
                                                </Row>

                                                <Row className="mt-3">
                                                  Holder Style:{" "}
                                                  {
                                                    item.attributes
                                                      .singlePhotoHolderType
                                                  }
                                                </Row>
                                              </>
                                            )}
                                            {item.attributes.lithoCategory ===
                                              "Cylinder" && (
                                              <>
                                                <Row className="mt-3">
                                                  Style:{" "}
                                                  {item.attributes.cylinderMode}
                                                </Row>
                                              </>
                                            )}

                                            {/* <Row className="mt-3">
                                              Holder Power:{" "}
                                              {item.attributes.holderPower}
                                            </Row> */}
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                  <CardFooter>
                                    <Button
                                      className="btn-link float-right"
                                      color="danger"
                                      onClick={() => this.deleteItem(item)}
                                    >
                                      <i className="tim-icons icon-trash-simple"></i>
                                    </Button>
                                    <Button
                                      className="btn-link float-right"
                                      color="success"
                                      onClick={() => this.viewPhotos(item)}
                                    >
                                      View Photos
                                    </Button>
                                    <Button
                                      className="btn-link float-right"
                                      color="warning"
                                      onClick={() => this.saveForLater(item.id)}
                                    >
                                      Save for later
                                    </Button>
                                  </CardFooter>
                                </Card>
                              ))}
                            </>
                          ) : (
                            <>
                              <div className="text-center">
                                <h3>Looks like your cart is empty!</h3>
                              </div>
                            </>
                          )}
                        </TabPane>
                        <TabPane tabId="savedForLater">
                          {this.state.savedForLater.length !== 0 ? (
                            <>
                              {this.state.savedForLater.map((item) => (
                                <Card key={item.id} style={{ opacity: ".8" }}>
                                  <CardHeader>
                                    <Label className="float-left">
                                      Order ID: {item.id}
                                    </Label>
                                    <span
                                      className="float-right "
                                      style={{
                                        color: "info",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <i className="fas fa-rupee-sign"></i>
                                      {item.attributes.estimatedPrice}
                                    </span>
                                  </CardHeader>
                                  <CardBody>
                                    <Row>
                                      <Col md="2">
                                        <img
                                          src={item.repPath}
                                          alt=""
                                          // width="400px"
                                          // height="300px"
                                        />
                                      </Col>
                                      <Col>
                                        <Row>
                                          <Col>
                                            <Row className="mt-3">
                                              Type:{" "}
                                              {item.attributes.lithoCategory}
                                            </Row>
                                            <Row className="mt-3">
                                              Size: {item.attributes.lithoSize}
                                            </Row>
                                            <Row className="mt-3">
                                              Number of Photos:{" "}
                                              {item.attributes.numPhotos}
                                            </Row>
                                          </Col>
                                          <Col>
                                            {item.attributes.lithoCategory ===
                                              "Single Photo" && (
                                              <>
                                                <Row className="mt-3">
                                                  Style:{" "}
                                                  {item.attributes.lithoStyle}
                                                </Row>

                                                <Row className="mt-3">
                                                  Holder Style:{" "}
                                                  {
                                                    item.attributes
                                                      .singlePhotoHolderType
                                                  }
                                                </Row>
                                              </>
                                            )}
                                            {item.attributes.lithoCategory ===
                                              "Cylinder" && (
                                              <>
                                                <Row className="mt-3">
                                                  Style:{" "}
                                                  {item.attributes.cylinderMode}
                                                </Row>
                                              </>
                                            )}
                                            {/* Aspect Ratio shows as float.  */}
                                            {/* Todo https://stackoverflow.com/questions/1186414/whats-the-algorithm-to-calculate-aspect-ratio */}
                                            {/* <Row className="mt-3">
                                            Aspect Ratio:{" "}
                                            {item.attributes.aspectRatio}
                                          </Row> */}
                                            {/* <Row className="mt-3">
                                              Holder Power:{" "}
                                              {item.attributes.holderPower}
                                            </Row> */}
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                  <CardFooter>
                                    <Button
                                      className="btn-link float-right"
                                      color="danger"
                                      onClick={() => this.deleteItem(item)}
                                    >
                                      <i className="tim-icons icon-trash-simple"></i>
                                    </Button>
                                    <Button
                                      className="btn-link float-right"
                                      color="success"
                                      onClick={() => this.viewPhotos(item)}
                                    >
                                      View Photos
                                    </Button>
                                    <Button
                                      className="btn-link float-right"
                                      color="warning"
                                      onClick={() => this.moveToCart(item.id)}
                                    >
                                      Move to cart
                                    </Button>
                                  </CardFooter>
                                </Card>
                              ))}
                            </>
                          ) : (
                            <>
                              <div className="text-center">
                                <h3>
                                  You haven't saved anything yet
                                  {/* Please click{" "}
                          <Button
                            className="btn btn-link"
                            tag={Link}
                            to="/products/lithophane"
                            color="success"
                            style={{ fontWeight: "1.4375rem" }}
                          >
                            here
                          </Button>{" "}
                          for some wizardry! */}
                                </h3>
                              </div>
                            </>
                          )}
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                  <Button
                    className="btn btn-round mb-5 float-right"
                    color="success"
                    size="lg"
                    type="button"
                    tag={Link}
                    to="/checkout"
                    id="buyNow"
                    name="buyNow"
                    disabled={this.state.cartItems.length === 0 ? true : false}
                  >
                    <i className="tim-icons icon-send"></i> Checkout
                  </Button>
                </>
              ) : (
                <>
                  <div className="text-center">
                    <h1>Please login to view your cart</h1>
                  </div>
                </>
              )}
            </Container>
          </div>
          <Modal
            modalClassName="modal-black modal-xl"
            isOpen={this.state.photosModal}
            toggle={() => this.toggleModal("photosModal")}
            size="lg"
          >
            <div className="modal-header justify-content-center">
              <button
                className="close"
                onClick={() => this.toggleModal("photosModal")}
              >
                <i className="tim-icons icon-simple-remove text-white" />
              </button>
              <UncontrolledCarousel items={this.state.carouselItems} />
            </div>
          </Modal>
          <Footer />
        </div>
      </>
    );
  }
}

export default Cart;
