/*!

=========================================================
* BLK Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import Parse from "parse";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

import Dropzone from "react-dropzone";

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.min.css";

// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";

import States from "../../components/States";

let ps = null;

// const cropper = React.createRef(null);

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      address: "",
      city: "",
      state: "",
      pincode: "",
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      hasChanged: false,
      validated: false,
      profileFilePickerModal: false,
      // profilePic: "",
      profilePicUrl: "",
      cropResult: null,
      src: null,
      isLoggedIn: false,
      loginModal: false,
      signUpModal: false
    };
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    document.body.classList.toggle("profile-page");
    this.getUserInfo();
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
    document.body.classList.toggle("profile-page");
  }

  toggleTabs = (e, stateName, index) => {
    e.preventDefault();
    this.setState({
      [stateName]: index
    });
  };
  //Mine
  handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
    let el = document.getElementById("updateProfile");
    el.firstChild.data = "Update";
    this.setState({ hasChanged: true });
  };
  validate = async () => {
    let {
      firstName,
      lastName,
      phone,
      address,
      city,
      state,
      pincode
    } = this.state;
    let validated = true;
    if (firstName === "") {
      document.getElementById("firstName").style.borderColor = "crimson";
      // await this.setState({ validated: false });
      validated = false;
    } else {
      // await this.setState({ validated: true });
    }
    if (lastName === "") {
      document.getElementById("lastName").style.borderColor = "crimson";
      // await this.setState({ validated: false });
      validated = false;
    } else {
      // await this.setState({ validated: true });
    }
    if (phone === "") {
      document.getElementById("phone").style.borderColor = "crimson";
      // await this.setState({ validated: false });
      validated = false;
    } else {
      // await this.setState({ validated: true });
    }
    if (address === "") {
      document.getElementById("address").style.borderColor = "crimson";
      // await this.setState({ validated: false });
      validated = false;
    } else {
      // await this.setState({ validated: true });
    }
    console.log(city);
    if (city === "") {
      document.getElementById("city").style.borderColor = "crimson";
      // await this.setState({ validated: false });
      validated = false;
    } else {
      // await this.setState({ validated: true });
    }
    if (state === "") {
      document.getElementById("state").style.borderColor = "crimson";
      // await this.setState({ validated: false });
      validated = false;
    } else {
      // await this.setState({ validated: true });
    }
    if (pincode === "" || isNaN(pincode)) {
      document.getElementById("pincode").style.borderColor = "crimson";
      // await this.setState({ validated: false });
      validated = false;
    } else {
      // await this.setState({ validated: true });
    }
    await this.setState({ validated });
  };
  handleSubmit = async () => {
    await this.validate();
    if (this.state.validated) {
      let user = Parse.User.current();
      user.set("firstName", this.state.firstName);
      user.set("lastName", this.state.lastName);
      user.set("phone", this.state.phone);
      user.set("address", this.state.address);
      user.set("city", this.state.city);
      user.set("state", this.state.state);
      user.set("pincode", this.state.pincode);
      try {
        await user.save().then(() => {
          this.setState({ hasChanged: false });
          this.getUserInfo();
          let el = document.getElementById("updateProfile");
          el.firstChild.data = "Updated";
        });
      } catch (err) {
        console.log(user.attributes);
      }
    }
  };
  getUserInfo = () => {
    let user = Parse.User.current();
    if (user) {
      this.setState({
        isLoggedIn: true,
        firstName: user.get("firstName"),
        lastName: user.get("lastName"),
        email: user.get("email"),
        address: user.get("address"),
        city: user.get("city"),
        state: user.get("state")
      });
      if (user.get("phone")) {
        this.setState({ phone: user.get("phone") });
      }
      if (user.get("pincode")) {
        this.setState({ pincode: user.get("pincode") });
      }
      if (user.get("profilePic")) {
        this.setState({
          profilePicUrl: user.get("profilePic").url()
        });
      } else {
        this.setState({
          profilePicUrl: "pics/avatar/profilePic.jpeg"
        });
      }
    } else {
      this.setState({ isLoggedIn: false });
    }
  };
  submitProfilePicture = async e => {
    e.preventDefault();
    // console.log(e);
    await this.cropImage();
    let el = document.getElementById("profileSubmit");
    el.firstChild.data = "Submitting";
    el.disabled = true;
    let user = Parse.User.current();
    var parseFile = new Parse.File(`${user}_profilePic.jpg`, {
      base64: this.state.cropResult
    });

    user.set("profilePic", parseFile);
    await user.save().then(() => {
      this.setState({ profileFilePickerModal: false });
      this.getUserInfo();
    });
  };

  toggleModal = modalState => {
    this.setState({
      [modalState]: !this.state[modalState]
    });
  };

  onFilesAccepted = file => {
    const reader = new FileReader();
    reader.onload = () => {
      var dataURL = reader.result;

      this.setState({ src: dataURL });

      // console.log(cropper);
    };
    try {
      reader.readAsDataURL(file[0]);
    } catch (error) {
      console.log(error);
    }
  };

  cropImage = () => {
    try {
      this.setState({
        cropResult: this.cropper.getCroppedCanvas().toDataURL()
      });
    } catch (err) {
      console.log(err);
    }
  };

  onChangeImage = e => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      this.setState({ src: reader.result });
    };
    reader.readAsDataURL(files[0]);
  };

  render() {
    return (
      <>
        <IndexNavbar
          getUserInfo={this.getUserInfo}
          loginModal={this.state.loginModal}
          signUpModal={this.state.signUpModal}
          toggleModal={this.toggleModal}
        />
        <div className="wrapper">
          {this.state.isLoggedIn ? (
            <>
              <div className="page-header">
                <img
                  alt="..."
                  className="dots"
                  src={require("assets/img/dots.png")}
                />
                <img
                  alt="..."
                  className="path"
                  src={require("assets/img/path4.png")}
                />
                <Container className="align-items-center">
                  <Row>
                    <Form>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <label>First Name</label>
                            <Input
                              type="text"
                              name="firstName"
                              id="firstName"
                              onChange={this.handleInputChange}
                              value={this.state.firstName}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <label>Last Name</label>
                            <Input
                              type="text"
                              name="lastName"
                              id="lastName"
                              onChange={this.handleInputChange}
                              value={this.state.lastName}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <label>Email address</label>
                            <Input
                              value={this.state.email}
                              type="email"
                              disabled
                            />
                          </FormGroup>
                        </Col>

                        <Col md="6">
                          <FormGroup>
                            <label>Phone</label>
                            <Input
                              type="text"
                              name="phone"
                              id="phone"
                              onChange={this.handleInputChange}
                              value={this.state.phone}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label>Address</label>
                            <Input
                              type="textarea"
                              name="address"
                              id="address"
                              placeholder="123, 8th Cross, 4th Main, Jayanagar"
                              onChange={this.handleInputChange}
                              value={this.state.address}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <label>City</label>
                            <Input
                              placeholder="Bangalore"
                              type="text"
                              name="city"
                              id="city"
                              onChange={this.handleInputChange}
                              value={this.state.city}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <label>State</label>

                            <Input
                              type="select"
                              name="state"
                              id="state"
                              onChange={this.handleInputChange}
                              value={this.state.state}
                            >
                              <States />>
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="md-6">
                          <FormGroup>
                            <label>Pincode</label>
                            <Input
                              placeholder="56001"
                              type="text"
                              name="pincode"
                              id="pincode"
                              onChange={this.handleInputChange}
                              value={this.state.pincode}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Button
                        className="btn-round float-right mb-3"
                        color="primary"
                        data-placement="right"
                        id="updateProfile"
                        type="button"
                        onClick={this.handleSubmit}
                        disabled={!this.state.hasChanged}
                      >
                        Update
                      </Button>
                    </Form>
                    <Col className="ml-auto mr-auto" lg="4" md="6">
                      <img
                        alt="..."
                        className="img-center img-fluid rounded-circle"
                        src={this.state.profilePicUrl}
                      />
                      <Form onSubmit={this.profileUpdate}>
                        <div className="text-center">
                          <Button
                            type="button"
                            className="btn-simple btn-round btn btn-primary btn-sm center-block mt-3"
                            onClick={() =>
                              this.toggleModal("profileFilePickerModal")
                            }
                          >
                            Change Profile Picture
                          </Button>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                  <Modal
                    modalClassName="modal-black modal-xl modal-profile"
                    isOpen={this.state.profileFilePickerModal}
                    toggle={() => this.toggleModal("profileFilePickerModal")}
                  >
                    <div className="modal-header justify-content-center">
                      <button
                        className="close"
                        onClick={() =>
                          this.toggleModal("profileFilePickerModal")
                        }
                      >
                        <i className="tim-icons icon-simple-remove text-white" />
                      </button>
                      <div className="text-muted text-center ml-auto mr-auto">
                        <h5 className="mb-0">
                          Select your new profile picture
                        </h5>
                      </div>
                    </div>
                    <div className="modal-body">
                      <div className="btn-wrapper text-center">
                        <Form onSubmit={this.submitProfilePicture}>
                          <Dropzone
                            onDrop={acceptedFiles =>
                              this.onFilesAccepted(acceptedFiles)
                            }
                            multiple={false}
                            accept="image/*"
                            autoQueue={false}
                            noClick
                            style={{ width: "100%" }}
                            // onChange={this.onChangeImage}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <section>
                                <div
                                  {...getRootProps({
                                    className: "dropzone",
                                    style: {
                                      flex: 1,
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      padding: "10px",
                                      borderWidth: "2px",
                                      borderRadius: "2px",
                                      borderColor: "#eeeeee",
                                      borderStyle: "dashed",
                                      backgroundColor: "rgba(255,255,255,0)",
                                      color: "rgba(255,255,255,0.8)",
                                      outline: "none",
                                      transition: "border .24s ease-in-out"
                                    }
                                  })}
                                >
                                  <input {...getInputProps()} />
                                  <div style={{ width: "100%" }}>
                                    <Cropper
                                      ref={cropper => {
                                        this.cropper = cropper;
                                      }}
                                      src={
                                        this.state.src
                                          ? this.state.src
                                          : this.state.profilePicUrl
                                      }
                                      style={{ height: 400, width: "100%" }}
                                      // Cropper.js options
                                      aspectRatio={1 / 1}
                                      guides={true}
                                      viewMode={1}
                                      dragMode={"move"}
                                      background={false}
                                      autoCropArea={1}
                                      highlight={false}
                                      modal={true}
                                      // cropBoxResizable={false}
                                    />
                                  </div>

                                  <p>Drag 'n' drop some files here</p>
                                </div>
                              </section>
                            )}
                          </Dropzone>
                        </Form>
                        <Button
                          className="btn-round float-right mt-3"
                          color="primary"
                          data-placement="right"
                          id="profileSubmit"
                          type="button"
                          onClick={this.submitProfilePicture}
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  </Modal>
                </Container>
              </div>
            </>
          ) : (
            <>
              <div className="page-header">
                <img
                  alt="..."
                  className="dots"
                  src={require("assets/img/dots.png")}
                />
                <img
                  alt="..."
                  className="path"
                  src={require("assets/img/path4.png")}
                />
                <Container className="align-items-center">
                  <div className="text-center">
                    <h1>Please login to view your profile</h1>
                  </div>
                </Container>
              </div>
            </>
          )}
          <Footer />
        </div>
      </>
    );
  }
}

export default Profile;
