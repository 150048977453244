import React, { Component } from "react";
import Parse from "parse";
import classnames from "classnames";
import {
  Button,
  Modal,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  FormGroup
} from "reactstrap";

class SignUpModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signUpModal: false,
      isLoggedIn: false,
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      terms: false,
      warning: "none",
      rememberMe: true
    };
  }
  componentDidMount() {
    // this.setState({ signUpModal: this.props.signUpModal });
  }
  componentWillReceiveProps({ signUpModal }) {
    this.setState({ signUpModal });
  }
  toggleModal = modalState => {
    this.setState({
      [modalState]: !this.state[modalState]
    });
  };
  handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };
  handleSignUp = async e => {
    let referrer = localStorage.getItem("referrer");
    if (this.state.terms) {
      e.preventDefault();
      let userDetails = {
        username: this.state.email,
        email: this.state.email,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        password: this.state.password,
        referrer
      };
      let user = new Parse.User(userDetails);
      try {
        await user.signUp().then(() => {
          this.props.getUserInfo();
          this.props.propsUserInfo();
          this.props.toggleModal("signUpModal");
        });
      } catch (error) {
        if (error.message === "Account already exists for this username.") {
          error.message = "Email is already in use. Please login.";
        }
        document.getElementById("signUpWarning").innerHTML = error.message;
        document.getElementById("signUpWarning").style.cssText =
          "display: block;color: rgba(255,255,255,.6);size: .8rem";
      }
    } else {
      document.getElementById("signUpWarning").style.cssText =
        "display: block;color: rgba(255,255,255,.6);size: .8rem";
    }
  };
  render() {
    return (
      <>
        {/* Start Sign Up Form Modal */}
        <Modal
          modalClassName="modal-black"
          isOpen={this.state.signUpModal}
          toggle={() => this.props.toggleModal("signUpModal")}
        >
          <div className="modal-header justify-content-center">
            <button
              className="close"
              onClick={() => this.props.toggleModal("signUpModal")}
            >
              <i className="tim-icons icon-simple-remove text-white" />
            </button>
            <div className="text-muted text-center ml-auto mr-auto">
              <h3 className="mb-0">Register</h3>
            </div>
          </div>
          <div className="modal-body">
            {/* <Card className="card-register">
              <CardHeader>
                <CardImg
                  alt="..."
                  src={require("assets/img/square-purple-1.png")}
                /> */}
            {/* <CardTitle tag="h4">Register</CardTitle> */}
            {/* </CardHeader>
              <CardBody> */}
            <Form className="form">
              <InputGroup
                className={classnames({
                  "input-group-focus": this.state.fullNameFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-single-02" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="First Name"
                  type="text"
                  onFocus={e => this.setState({ fullNameFocus: true })}
                  onBlur={e => this.setState({ fullNameFocus: false })}
                  name="firstName"
                  onChange={this.handleInputChange}
                  value={this.state.firstName}
                />
                <Input
                  placeholder="Last Name"
                  type="text"
                  onFocus={e => this.setState({ fullNameFocus: true })}
                  onBlur={e => this.setState({ fullNameFocus: false })}
                  name="lastName"
                  onChange={this.handleInputChange}
                  value={this.state.lastName}
                />
              </InputGroup>
              <InputGroup
                className={classnames({
                  "input-group-focus": this.state.emailFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-email-85" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Email"
                  type="text"
                  onFocus={e => this.setState({ emailFocus: true })}
                  onBlur={e => this.setState({ emailFocus: false })}
                  name="email"
                  onChange={this.handleInputChange}
                  value={this.state.email}
                />
              </InputGroup>
              <InputGroup
                className={classnames({
                  "input-group-focus": this.state.passwordFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-lock-circle" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Password"
                  type="password"
                  onFocus={e => this.setState({ passwordFocus: true })}
                  onBlur={e => this.setState({ passwordFocus: false })}
                  name="password"
                  onChange={this.handleInputChange}
                  value={this.state.password}
                />
              </InputGroup>
              <FormGroup check className="text-left">
                <Label check>
                  <Input
                    type="checkbox"
                    name="terms"
                    onChange={this.handleInputChange}
                  />
                  <span className="form-check-sign" />I agree to the{" "}
                  <a href="/terms-and-conditions" target="_blank">
                    terms and conditions
                  </a>
                  .
                </Label>
              </FormGroup>
            </Form>
            {/* </CardBody> */}
            <div
              id="signUpWarning"
              className="text-center"
              style={{ display: this.state.warning }}
            >
              Please agree to our terms and conditions to proceed!
            </div>
            {/* <CardFooter> */}
            <div className="text-center">
              <Button
                className="mt-3"
                color="primary"
                size="lg"
                onClick={this.handleSignUp}
              >
                Sign Up!
              </Button>
            </div>
            {/* </CardFooter>
            </Card> */}
          </div>
        </Modal>
        {/* End Sign Up Form Modal */}
      </>
    );
  }
}

export default SignUpModal;
