import React, { Component } from "react";
import * as THREE from "three";

class ThreeDViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.init();
  }
  init = () => {
    const container = document.getElementById("scene-container");

    // create scene
    const scene = new THREE.Scene();
    scene.background = new THREE.Color("#32325d");
    // const light = new THREE.AmbientLight();
    // scene.add(light);

    //create camera
    const fov = 35;
    const aspect = container.clientWidth / container.clientHeight;
    console.log(container.clientHeight);
    const near = 0.1;
    const far = 100;
    const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
    camera.position.set(0, 0, 10);

    //create objects
    const box = new THREE.BoxBufferGeometry(2, 2, 2);
    const material = new THREE.MeshBasicMaterial();
    const mesh = new THREE.Mesh(box, material);

    //add mesh to scene
    scene.add(mesh);

    //render it
    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(container.clientWidth, container.clientHeight);
    renderer.setPixelRatio(window.devicePixelRatio);

    //add renderer to container
    container.appendChild(renderer.domElement);

    renderer.render(scene, camera);

    // console.log(THREE);
  };
  render() {
    return (
      <div id="scene-container" style={{ width: "640px", height: "360px" }}>
        {/* <canvas></canvas> */}
      </div>
    );
  }
}

export default ThreeDViewer;
