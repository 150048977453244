import React, { Component } from "react";
import { Link } from "react-router-dom";
import Parse from "parse";

import {
  Button,
  Form,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Modal,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  UncontrolledCarousel,
} from "reactstrap";

import IndexNavbar from "components/Navbars/IndexNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";

import logo from "assets/img/small.jpg";

import States from "../../components/States";

class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      userId: "",
      loginModal: false,
      signUpModal: false,
      photosModal: false,
      checkoutModal: false,
      hasChanged: false,
      carouselItems: [],
      cartItems: [],
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      address: "",
      city: "",
      state: "",
      pincode: "",
      validated: false,
      subTotal: 0,
      shipping: 0,
      cartTotal: 0,
      orderID: "",
      paymentInProcess: false,
      paymentComplete: "",
      payClicked: false,
      pg: "razorpay",
      discountCode: "",
      discountRate: 0,
      discount: 0,
    };
    this.asyncForEach = this.asyncForEach.bind(this);
  }
  componentDidMount() {
    this.getUserInfo();
    this.getCartItems();
    document.body.classList.toggle("profile-page");

    const script = document.createElement("script");
    script.type = "text/javascript";
    // script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.src = "/js/vendor/razorpay/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }

  componentWillUnmount() {
    document.body.classList.toggle("profile-page");
  }
  toggleTabs = (e, stateName, index) => {
    e.preventDefault();
    this.setState({
      [stateName]: index,
    });
  };
  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
    document.getElementById([name]).style.borderColor = "#2b3553";
    let el = document.getElementById("updateProfile");
    el.firstChild.data = "Update";
    this.setState({ hasChanged: true });
  };
  asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  };
  getCartItems = async () => {
    const Items = Parse.Object.extend("Items");
    const ItemsQuery = new Parse.Query(Items);
    ItemsQuery.equalTo("User", Parse.User.current());
    ItemsQuery.equalTo("status", "In Cart");
    let cartItemsQuery = await ItemsQuery.find();
    let cartItems = [];
    let subTotal = 0;
    let shipping = 100;
    let cartTotal = 0;
    let itemIDs = [];
    let discountRate = this.state.discountRate;

    cartItemsQuery.length !== 0
      ? await this.asyncForEach(cartItemsQuery, async (item) => {
          const cartItemDetails = new Parse.Query(Items);
          let cartItem = await cartItemDetails.get(item.id);
          cartItem.repPath = await this.repPhotoPath(item);
          cartItems.push(cartItem);
          itemIDs.push(item.id);
          subTotal += cartItem.attributes.estimatedPrice;
        })
      : await this.setState({ cartItems, subTotal });
    subTotal < 500 ? (shipping = 100) : (shipping = 0);

    cartTotal =
      subTotal + shipping - Math.round(subTotal * discountRate * 100) / 100;
    this.setState({
      shipping,
      cartItems,
      subTotal,
      cartTotal,
      discount: Math.round(subTotal * discountRate * 100) / 100,
    });
    return { cartItems, cartTotal, subTotal, itemIDs };
  };
  repPhotoPath = (item) => {
    let firstPathPart = `/pics/repBox/`;
    let secondPathPart = ``;
    if (item.attributes.lithoCategory === "Single Photo") {
      secondPathPart = `singlePhoto/${item.attributes.lithoStyle}`;
    } else if (item.attributes.lithoCategory === "Cube") {
      secondPathPart = `cube/${item.attributes.numPhotos}`;
      // secondPathPart = `cube/5`;
    } else if (item.attributes.lithoCategory === "Keychain") {
      secondPathPart = `keychain/`;
    }
    return `${firstPathPart}${secondPathPart}/Lights_on.jpg-xsmall.jpg`;
  };

  toggleModal = (modalState) => {
    this.setState({
      [modalState]: !this.state[modalState],
    });
  };
  viewPhotos = (order) => {
    this.toggleModal("photosModal");
    let carouselItems = [];
    for (let i = 1; i <= order.attributes.numPhotos; i++) {
      let photo = "photo" + i;
      let carouselObject = {
        src: order.attributes[photo]._url,
        altText: `Photo ${i}`,
        caption: `Photo ${i}`,
      };
      carouselItems.push(carouselObject);
      this.setState({ carouselItems });
    }
  };
  getUserInfo = () => {
    let user = Parse.User.current();
    if (user) {
      this.setState({
        isLoggedIn: true,
        userId: user.id,
        firstName: user.get("firstName"),
        lastName: user.get("lastName"),
        email: user.get("email"),
        state: user.get("state"),
      });
      if (user.get("city")) {
        this.setState({ city: user.get("city") });
      }
      if (user.get("address")) {
        this.setState({ address: user.get("address") });
      }
      if (user.get("phone")) {
        this.setState({ phone: user.get("phone") });
      }
      if (user.get("pincode")) {
        this.setState({ pincode: user.get("pincode") });
      }
    } else {
      this.setState({ isLoggedIn: false });
    }
  };
  viewPhotos = (order) => {
    this.toggleModal("photosModal");
    let carouselItems = [];
    for (let i = 1; i <= order.attributes.numPhotos; i++) {
      let photo = "photo" + i;
      let carouselObject = {
        src: order.attributes[photo]._url,
        altText: `Photo ${i}`,
        caption: `Photo ${i}`,
      };
      carouselItems.push(carouselObject);
      this.setState({ carouselItems });
    }
  };
  handleDiscountInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });

    // this.setState({ hasChanged: true });
  };
  handleProfileUpdate = async () => {
    let user = Parse.User.current();
    user.set("firstName", this.state.firstName);
    user.set("lastName", this.state.lastName);
    user.set("phone", this.state.phone);
    user.set("address", this.state.address);
    user.set("city", this.state.city);
    user.set("state", this.state.state);
    user.set("pincode", this.state.pincode);
    try {
      await user.save().then(() => {
        this.setState({ hasChanged: false });
        this.getUserInfo();
      });
    } catch (err) {
      console.log(err);
    }
  };
  validate = async () => {
    let {
      firstName,
      lastName,
      phone,
      address,
      city,
      state,
      pincode,
    } = this.state;
    let validated = true;
    if (firstName === "") {
      document.getElementById("firstName").style.borderColor = "crimson";
      // await this.setState({ validated: false });
      validated = false;
    } else {
      // await this.setState({ validated: true });
    }
    if (lastName === "") {
      document.getElementById("lastName").style.borderColor = "crimson";
      // await this.setState({ validated: false });
      validated = false;
    } else {
      // await this.setState({ validated: true });
    }
    if (phone === "") {
      document.getElementById("phone").style.borderColor = "crimson";
      // await this.setState({ validated: false });
      validated = false;
    } else {
      // await this.setState({ validated: true });
    }
    if (address === "") {
      document.getElementById("address").style.borderColor = "crimson";
      // await this.setState({ validated: false });
      validated = false;
    } else {
      // await this.setState({ validated: true });
    }
    if (city === "") {
      document.getElementById("city").style.borderColor = "crimson";
      // await this.setState({ validated: false });
      validated = false;
    } else {
      // await this.setState({ validated: true });
    }
    if (state === "") {
      document.getElementById("state").style.borderColor = "crimson";
      // await this.setState({ validated: false });
      validated = false;
    } else {
      // await this.setState({ validated: true });
    }
    if (pincode === "" || isNaN(pincode)) {
      document.getElementById("pincode").style.borderColor = "crimson";
      // await this.setState({ validated: false });
      validated = false;
    } else {
      // await this.setState({ validated: true });
    }
    await this.setState({ validated });
    if (this.state.payClicked && this.state.validated) {
      if (this.state.pg === "paytm") {
        this.handlePaytm();
      } else if (this.state.pg === "razorpay") {
        this.handleRazorPay();
      }
    }
  };
  handlePaytm = async () => {
    await this.setState({ pg: "paytm" });
    if (this.state.validated) {
      await this.handleProfileUpdate();
      let { itemIDs, cartTotal } = await this.getCartItems();
      let newOrder = new Parse.Object("Orders");
      let orderID = "";
      newOrder.set("User", Parse.User.current());
      newOrder.set("Items", itemIDs);
      await newOrder.save().then((order) => {
        orderID = order.id;
      });
      const Orders = Parse.Object.extend("Orders");
      const OrdersQuery = new Parse.Query(Orders);
      const Items = Parse.Object.extend("Items");
      const ItemsQuery = new Parse.Query(Items);

      await this.setState({ cartTotal, orderID });
      let txnStatus = setInterval(async () => {
        let currentOrder = await OrdersQuery.get(orderID);
        console.log(currentOrder.attributes);
        if (currentOrder.attributes.STATUS) {
          if (currentOrder.attributes.STATUS === "TXN_SUCCESS") {
            this.asyncForEach(currentOrder.attributes.Items, async (item) => {
              let currentItem = await ItemsQuery.get(item);
              currentItem.set("status", "Getting Ready");
              await currentItem.save();
            });
            console.log("Success");
            await this.setState({
              paymentInProcess: false,
              paymentComplete: "success",
            });

            clearInterval(txnStatus);
            this.getCartItems();
          } else {
            console.log("Failure");
            await this.setState({
              paymentInProcess: false,
              paymentComplete: "failure",
            });
            clearInterval(txnStatus);
          }
        } else {
          console.log("Transaction Ongoing");
        }
      }, 1500);
      await document.paytm.submit();
      await this.setState({ paymentInProcess: true });
    } else {
      await this.setState({ payClicked: true });
      await this.validate();
    }
  };

  handleRazorPay = async () => {
    if (this.state.cartTotal > 0) {
      await this.setState({ pg: "razorpay" });
      if (this.state.validated) {
        await this.handleProfileUpdate();
        let { itemIDs, cartTotal } = await this.getCartItems();
        await this.setState({ cartTotal });

        const options = {
          key: "rzp_live_2XnBbJtsY5Xrpy",
          amount: this.state.cartTotal * 100, // 2000 paise
          name: "Gift Wizards",
          // description: "Purchase Description",
          image: logo,
          handler: async (resp) => {
            let payment_id = resp.razorpay_payment_id;

            let newOrder = new Parse.Object("Orders");
            newOrder.set("User", Parse.User.current());
            newOrder.set("Items", itemIDs);
            newOrder.set("pg", "RazorPay");
            newOrder.set("Payment ID", payment_id);
            newOrder.save().then(() => {
              const Items = Parse.Object.extend("Items");
              const ItemsQuery = new Parse.Query(Items);
              this.asyncForEach(itemIDs, async (item) => {
                let currentItem = await ItemsQuery.get(item);
                currentItem.set("status", "Getting Ready");
                await currentItem.save();
              });
              this.setState({
                paymentComplete: "success",
              });
            });
          },
          prefill: {
            name: `${this.state.firstName} ${this.state.lastName}`,
            email: this.state.email,
            contact: this.state.phone,
          },
          notes: {
            address: this.state.address,
          },
          theme: {
            color: "#528FF0",
          },
        };
        const rzp = new window.Razorpay(options);
        // Open Gateway
        rzp.open();
      } else {
        await this.setState({ payClicked: true });
        await this.validate();
      }
    } else {
      await this.handleProfileUpdate();
      let { itemIDs, cartTotal } = await this.getCartItems();
      await this.setState({ cartTotal });
      let newOrder = new Parse.Object("Orders");
      newOrder.set("User", Parse.User.current());
      newOrder.set("Items", itemIDs);
      newOrder.set("pg", "free");
      // newOrder.set("Payment ID", payment_id);
      newOrder.save().then(() => {
        const Items = Parse.Object.extend("Items");
        const ItemsQuery = new Parse.Query(Items);
        this.asyncForEach(itemIDs, async (item) => {
          let currentItem = await ItemsQuery.get(item);
          currentItem.set("status", "Getting Ready");
          await currentItem.save();
        });
        this.setState({
          paymentComplete: "success",
        });
      });
    }
  };
  applyDiscount = async () => {
    const Discounts = Parse.Object.extend("Discounts");
    const CodesQuery = new Parse.Query(Discounts);
    CodesQuery.equalTo("code", this.state.discountCode);
    let Codes = await CodesQuery.find();
    if (Codes.length) {
      await this.setState({ discountRate: Codes[0].attributes.rate });
      let el = document.getElementById("discountMsg");
      el.innerHTML = '<span class="text-success">Discount code applied!</span>';
      this.getCartItems();
    } else {
      let el = document.getElementById("discountMsg");
      el.innerHTML =
        '<span class="text-danger">Sorry! That code is not valid.</span>';
    }
  };
  render() {
    return (
      <>
        <IndexNavbar
          getUserInfo={this.getUserInfo}
          loginModal={this.state.loginModal}
          signUpModal={this.state.signUpModal}
          toggleModal={this.toggleModal}
        />
        <div className="wrapper">
          <div className="page-header">
            <img
              alt="..."
              className="dots"
              src={require("assets/img/dots.png")}
              style={{ zIndex: "-1" }}
            />
            <img
              alt="..."
              className="path"
              src={require("assets/img/path2.png")}
              style={{ zIndex: "-1" }}
            />
            <Container
              className="align-items-center"
              style={{ paddingTop: "110px", marginBottom: "30px" }}
            >
              {this.state.isLoggedIn ? (
                this.state.paymentInProcess ? (
                  <>
                    <div className="text-center">
                      <h1>
                        {" "}
                        <img
                          src="https://upload.wikimedia.org/wikipedia/commons/0/0c/Vector_Loading.svg"
                          alt=""
                          style={{ animation: "rotation 2s infinite linear" }}
                        />{" "}
                        Please do not close this tab.{" "}
                      </h1>
                      <h3>
                        We will wait for you to complete your payment in the new
                        tab.{" "}
                      </h3>
                    </div>
                  </>
                ) : this.state.paymentComplete === "success" ? (
                  <>
                    <div className="text-center">
                      <h1>Hooray! We have recieved your payment.</h1>
                      <p>
                        {" "}
                        You may check the status of your order in the "My
                        Orders" section.
                      </p>
                    </div>
                  </>
                ) : this.state.paymentComplete === "failure" ? (
                  <>
                    <div className="text-center">
                      <h1>Uh Oh! Something went wrong with your payment.</h1>{" "}
                      <p>
                        You may retry your payment or check for details in your
                        "My Orders" section
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <Row>
                      <Col lg="8">
                        <>
                          <Row>
                            <Col md="6">
                              <FormGroup>
                                <label className="labels">
                                  First Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Input
                                  type="text"
                                  name="firstName"
                                  id="firstName"
                                  onChange={this.handleInputChange}
                                  value={this.state.firstName}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <label className="labels">
                                  Last Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Input
                                  type="text"
                                  name="lastName"
                                  id="lastName"
                                  onChange={this.handleInputChange}
                                  value={this.state.lastName}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6">
                              <FormGroup>
                                <label className="labels">
                                  Email address{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Input
                                  value={this.state.email}
                                  type="email"
                                  disabled
                                />
                              </FormGroup>
                            </Col>

                            <Col md="6">
                              <FormGroup>
                                <label className="labels">
                                  Phone <span className="text-danger">*</span>
                                </label>
                                <Input
                                  type="text"
                                  name="phone"
                                  id="phone"
                                  onChange={this.handleInputChange}
                                  value={this.state.phone}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12">
                              <FormGroup>
                                <label className="labels">
                                  Address <span className="text-danger">*</span>
                                </label>
                                <Input
                                  type="textarea"
                                  name="address"
                                  id="address"
                                  placeholder="123, 8th Cross, 4th Main, Jayanagar"
                                  onChange={this.handleInputChange}
                                  value={this.state.address}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6">
                              <FormGroup>
                                <label className="labels">
                                  City <span className="text-danger">*</span>
                                </label>
                                <Input
                                  placeholder="Bangalore"
                                  type="text"
                                  name="city"
                                  id="city"
                                  onChange={this.handleInputChange}
                                  value={this.state.city}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <label className="labels">
                                  State <span className="text-danger">*</span>
                                </label>

                                <Input
                                  type="select"
                                  name="state"
                                  id="state"
                                  onChange={this.handleInputChange}
                                  value={this.state.state}
                                >
                                  <States />
                                </Input>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="md-6">
                              <FormGroup>
                                <label className="labels">
                                  Pincode <span className="text-danger">*</span>
                                </label>
                                <Input
                                  placeholder="56001"
                                  type="text"
                                  name="pincode"
                                  id="pincode"
                                  onChange={this.handleInputChange}
                                  value={this.state.pincode}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <div className="d-flex justify-content-between align-items-center">
                            <Link to="/cart">
                              <span className="fas fa-angle-left mr-2"></span>
                              Return to cart
                            </Link>
                          </div>

                          {/* <Button
                        className="btn-round float-right mb-3"
                        color="primary"
                        data-placement="right"
                        id="updateProfile"
                        type="button"
                        onClick={this.handleSubmit}
                        disabled={!this.state.hasChanged}
                      >
                        Update
                      </Button> */}
                        </>
                      </Col>

                      <Col lg="4">
                        <Card>
                          <CardHeader>Order Summary</CardHeader>

                          <CardBody>
                            {/* <ol> */}

                            {this.state.cartItems.map((item) => (
                              <React.Fragment key={item.id}>
                                {/* <li  className="mt-3"> */}{" "}
                                <div className="align-items-center mb-3 media">
                                  <div className="col-6 col-md-5">
                                    <img
                                      src={item.repPath}
                                      alt=""
                                      // width="400px"
                                      // height="300px"
                                    />{" "}
                                  </div>
                                  <div className="media-body">
                                    <h2 className="h6">
                                      {" "}
                                      {item.attributes.lithoCategory}
                                    </h2>
                                    <small className="d-block text-secondary">
                                      <Button
                                        className="btn-link btn-sm"
                                        color="info"
                                        onClick={() => this.viewPhotos(item)}
                                        style={{
                                          marginTop: "-1px",
                                          padding: "0px",
                                        }}
                                      >
                                        View Photos
                                      </Button>
                                    </small>
                                  </div>
                                  <div className="text-right media-body">
                                    <span
                                    // className="float-right"
                                    // style={{ marginTop: "10px" }}
                                    >
                                      <i className="fas fa-rupee-sign"></i>{" "}
                                      {item.attributes.estimatedPrice}
                                    </span>
                                  </div>
                                </div>
                                {/* </li> */}
                              </React.Fragment>
                            ))}
                            <hr className="line-info mb-5" />
                            <Form className="code-validate">
                              <label className="labels">Discount</label>
                              <div className="input-group">
                                <input
                                  aria-label="Discount"
                                  name="discountCode"
                                  placeholder="Discount Code"
                                  type="text"
                                  className="form-control"
                                  value={this.state.discountCode}
                                  onChange={this.handleDiscountInputChange}
                                ></input>
                                <div className="input-group-append">
                                  <button
                                    type="button"
                                    className="btn btn-info"
                                    style={{
                                      marginTop: "-1px",
                                      paddingTop: "-2px",
                                    }}
                                    onClick={this.applyDiscount}
                                  >
                                    Apply
                                  </button>
                                </div>
                              </div>
                            </Form>
                            <div id="discountMsg"></div>
                            <hr className="line-info mb-3" />
                            <div className="align-items-center media">
                              <h3 className="h6 text-secondary mr-3">
                                SubTotal
                              </h3>
                              <div className="text-right media-body">
                                <span>
                                  <i className="fas fa-rupee-sign"></i>{" "}
                                  {this.state.subTotal}
                                </span>
                              </div>
                            </div>
                            <div className="align-items-center media">
                              <h3 className="h6 text-secondary mr-3">
                                Shipping
                              </h3>
                              <div className="text-right media-body">
                                <span>
                                  <i className="fas fa-rupee-sign"></i>{" "}
                                  {this.state.shipping}
                                </span>
                              </div>
                            </div>
                            <div className="align-items-center media">
                              <h3 className="h6 text-secondary mr-3">
                                Discount
                              </h3>
                              <div className="text-right media-body">
                                <span>
                                  <i className="fas fa-rupee-sign"></i>
                                  {this.state.discount
                                    ? -this.state.discount
                                    : this.state.discount}
                                </span>
                              </div>
                            </div>
                            <hr className="line-info mb-3" />
                            <div className="align-items-center media">
                              <h3 className="h6">Total</h3>
                              <div className="text-right media-body">
                                <span className="font-weight-semi-bold">
                                  <i className="fas fa-rupee-sign"></i>{" "}
                                  {this.state.cartTotal}
                                </span>
                              </div>
                            </div>
                          </CardBody>
                          <CardFooter className="text-center">
                            {/* <h4 className="text-center">Pay Using:</h4> */}
                            {/* <Button
                              className="btn-round mb-3"
                              color="primary"
                              // data-placement="right"
                              id="updateProfile"
                              type="button"
                              onClick={this.handlePaytm}
                            >
                              Pay
                            </Button> */}
                            <Button
                              className="btn-round mb-3"
                              color="primary"
                              // data-placement="right"
                              id="updateProfile"
                              type="button"
                              onClick={this.handleRazorPay}
                            >
                              Pay
                            </Button>
                            <div className="">
                              <form
                                method="POST"
                                // action="https://securegw-stage.paytm.in/order/process"
                                action="https://api.giftwizards.in/payments/paytm"
                                name="paytm"
                                // onSubmit="window.open('', )"
                                target="_blank"
                              >
                                <input
                                  type="hidden"
                                  name="WEBSITE"
                                  value="giftwizards"
                                />
                                <input
                                  type="hidden"
                                  name="ORDER_ID"
                                  value={this.state.orderID}
                                />
                                <input
                                  type="hidden"
                                  name="CUST_ID"
                                  value={this.state.userId}
                                />
                                <input
                                  type="hidden"
                                  name="MOBILE_NO"
                                  value={this.state.phone}
                                />
                                <input
                                  type="hidden"
                                  name="EMAIL"
                                  value={this.state.email}
                                />
                                <input
                                  type="hidden"
                                  name="INDUSTRY_TYPE_ID"
                                  value="Retail"
                                />
                                <input
                                  type="hidden"
                                  name="CHANNEL_ID"
                                  value="WEB"
                                />
                                <input
                                  type="hidden"
                                  name="TXN_AMOUNT"
                                  value={this.state.cartTotal}
                                />
                                <input
                                  type="hidden"
                                  name="CALLBACK_URL"
                                  value="https://api.giftwizards.in/payments/paytm/response"
                                />
                              </form>
                            </div>
                          </CardFooter>
                        </Card>
                      </Col>
                    </Row>
                  </>
                )
              ) : (
                <>
                  <div className="text-center">
                    <h1>Please login to buy your potions</h1>
                  </div>
                </>
              )}
            </Container>
          </div>
          <Modal
            modalClassName="modal-black modal-xl"
            isOpen={this.state.photosModal}
            toggle={() => this.toggleModal("photosModal")}
            size="lg"
          >
            <div className="modal-header justify-content-center">
              <button
                className="close"
                onClick={() => this.toggleModal("photosModal")}
              >
                <i className="tim-icons icon-simple-remove text-white" />
              </button>
              <UncontrolledCarousel items={this.state.carouselItems} />
            </div>
          </Modal>

          <Footer />
        </div>
      </>
    );
  }
}

export default Checkout;
