import React, { Component } from "react";
import Parse from "parse";
import {
  Button,
  Container,
  Row,
  Col,
  Modal,
  Label,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  UncontrolledCarousel,
} from "reactstrap";

import IndexNavbar from "components/Navbars/IndexNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";

class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      loginModal: false,
      signUpModal: false,
      photosModal: false,
      orderDetailsModal: false,
      carouselItems: [],
      orders: [],
    };
  }
  componentDidMount() {
    this.getUserInfo();
    this.getOrderedItems();
    document.body.classList.toggle("profile-page");
  }
  componentWillUnmount() {
    document.body.classList.toggle("profile-page");
  }
  toggleTabs = (e, stateName, index) => {
    e.preventDefault();
    this.setState({
      [stateName]: index,
    });
  };
  asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  };

  getUserInfo = async () => {
    let user = Parse.User.current();
    if (user) {
      this.setState({
        isLoggedIn: true,
      });
    } else {
      this.setState({ isLoggedIn: false });
    }
  };
  getOrderedItems = async () => {
    const Orders = Parse.Object.extend("Orders");
    const userQuery = new Parse.Query(Orders);
    userQuery.equalTo("User", Parse.User.current());
    const orderQuery = new Parse.Query(Orders);
    orderQuery.descending("createdAt");
    let ordersQuery = await orderQuery.find();

    const Items = Parse.Object.extend("Items");
    const itemQuery = new Parse.Query(Items);

    let orders = [];

    ordersQuery.length !== 0
      ? await this.asyncForEach(ordersQuery, async (order) => {
          let eachOrder = {
            id: order.id,
            items: [],
            status: order.attributes.RESPMSG,
            time: order.attributes.TXNDATE,
            amount: order.attributes.TXNAMOUNT,
            txnid: order.attributes.TXNID,
            bankName: order.attributes.BANKNAME,
            bankTxnId: order.attributes.BANKTXNID,
            paymentMode: order.attributes.PAYMENTMODE,
            gateway: order.attributes.GATEWAYNAME,
          };
          await this.asyncForEach(order.attributes.Items, async (item) => {
            let orderItem = await itemQuery.get(item);
            orderItem.repPath = await this.repPhotoPath(orderItem);
            // console.log(orderItem);
            eachOrder.items.push(orderItem);
          });
          orders.push(eachOrder);
        })
      : console.log("No orders");
    this.setState({ orders });
  };
  repPhotoPath = (item) => {
    let firstPathPart = `/pics/repBox/`;
    let secondPathPart = ``;
    if (item.attributes.lithoCategory === "Single Photo") {
      secondPathPart = `singlePhoto/${item.attributes.lithoStyle}`;
    } else if (item.attributes.lithoCategory === "Cube") {
      secondPathPart = `cube/${item.attributes.numPhotos}`;
      // secondPathPart = `cube/5`;
    } else if (item.attributes.lithoCategory === "Keychain") {
      secondPathPart = `keychain/`;
    }
    return `${firstPathPart}${secondPathPart}/Lights_on.jpg-xsmall.jpg`;
  };

  toggleModal = (modalState) => {
    this.setState({
      [modalState]: !this.state[modalState],
    });
  };
  viewPhotos = (order) => {
    this.toggleModal("photosModal");
    let carouselItems = [];
    for (let i = 1; i <= order.attributes.numPhotos; i++) {
      let photo = "photo" + i;
      let carouselObject = {
        src: order.attributes[photo]._url,
        altText: `Photo ${i}`,
        caption: `Photo ${i}`,
      };
      carouselItems.push(carouselObject);
      this.setState({ carouselItems });
    }
  };
  orderDetails = async (order) => {
    let html = "";

    html += "<b>Status Check Response</b><br>";

    for (var x in order) {
      if (x === "items") {
      } else {
        html += x + " : " + order[x] + "<br/>";
      }
    }
    await this.toggleModal("orderDetailsModal");
    document.getElementById("orderDetails").innerHTML = html;
    console.log(order);
  };

  render() {
    return (
      <>
        <IndexNavbar
          getUserInfo={this.getUserInfo}
          loginModal={this.state.loginModal}
          signUpModal={this.state.signUpModal}
          toggleModal={this.toggleModal}
        />
        <div className="wrapper">
          {this.state.isLoggedIn ? (
            <>
              {this.state.orders.length !== 0 ? (
                <>
                  <div className="page-header" style={{ minHeight: "50px" }}>
                    <Container className="text-center">
                      <h1>Your Orders</h1>
                    </Container>
                  </div>
                </>
              ) : (
                <>
                  <div className="page-header" style={{ minHeight: "50px" }}>
                    <Container className="text-center">
                      <div className="text-center">
                        <h3>You haven't ordered anything yet.</h3>
                      </div>
                    </Container>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <div className="page-header" style={{ minHeight: "50px" }}>
                <Container className="text-center">
                  <div className="text-center">
                    <h1>Please login to view your orders</h1>
                  </div>
                </Container>
              </div>
            </>
          )}
          <div className="page-body">
            <img
              alt="..."
              className="dots"
              src={require("assets/img/dots.png")}
              style={{ zIndex: "-1" }}
            />
            <img
              alt="..."
              className="path"
              src={require("assets/img/path2.png")}
              style={{ zIndex: "-1" }}
            />
            <Container
              className="align-items-center"
              style={{ paddingTop: "110px", marginBottom: "30px" }}
            >
              {this.state.isLoggedIn ? (
                <>
                  {this.state.orders.length !== 0 ? (
                    <>
                      {this.state.orders.map((order) => (
                        <Card
                          key={order.id}
                          style={{
                            backgroundColor: "rgba(0,0,0,0)",
                            border: "2px solid rgba(0,0,0,.2)",
                          }}
                        >
                          <CardHeader>
                            <Label className="float-left">
                              Order ID: {order.id}
                            </Label>
                          </CardHeader>
                          {order.items.map((item) => (
                            <CardBody key={item.id}>
                              <Card style={{ opacity: ".8" }}>
                                <CardHeader>
                                  <Label className="float-left">
                                    Item ID: {item.id}
                                  </Label>
                                  <span
                                    className="float-right "
                                    style={{
                                      color: "info",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <i className="fas fa-rupee-sign"></i>
                                    {item.attributes.estimatedPrice}
                                  </span>
                                </CardHeader>
                                <CardBody>
                                  <Row>
                                    <Col md="2">
                                      <img
                                        src={item.repPath}
                                        alt=""
                                        // width="400px"
                                        // height="300px"
                                      />
                                    </Col>
                                    <Col>
                                      <Row>
                                        <Col>
                                          <Row className="mt-3">
                                            Type:{" "}
                                            {item.attributes.lithoCategory}
                                          </Row>
                                          <Row className="mt-3">
                                            Size: {item.attributes.lithoSize}
                                          </Row>
                                          <Row className="mt-3">
                                            Number of Photos:{" "}
                                            {item.attributes.numPhotos}
                                          </Row>
                                        </Col>
                                        <Col>
                                          {item.attributes.lithoCategory ===
                                            "Single Photo" && (
                                            <>
                                              <Row className="mt-3">
                                                Style:{" "}
                                                {item.attributes.lithoStyle}
                                              </Row>

                                              <Row className="mt-3">
                                                Holder Style:{" "}
                                                {
                                                  item.attributes
                                                    .singlePhotoHolderType
                                                }
                                              </Row>
                                            </>
                                          )}
                                          {item.attributes.lithoCategory ===
                                            "Cylinder" && (
                                            <>
                                              <Row className="mt-3">
                                                Style:{" "}
                                                {item.attributes.cylinderMode}
                                              </Row>
                                            </>
                                          )}
                                          {/* Aspect Ratio shows as float.  */}
                                          {/* Todo https://stackoverflow.com/questions/1186414/whats-the-algorithm-to-calculate-aspect-ratio */}
                                          {/* <Row className="mt-3">
                                            Aspect Ratio:{" "}
                                            {item.attributes.aspectRatio}
                                          </Row> */}
                                          {/* <Row className="mt-3">
                                              Holder Power:{" "}
                                              {item.attributes.holderPower}
                                            </Row> */}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </CardBody>
                                <CardFooter>
                                  <span>
                                    Item Status:{" "}
                                    <span>{item.attributes.status}</span>
                                  </span>
                                  <Button
                                    className="btn-link float-right"
                                    color="success"
                                    onClick={() => this.viewPhotos(item)}
                                  >
                                    View Photos
                                  </Button>
                                </CardFooter>
                              </Card>
                            </CardBody>
                          ))}
                          <CardFooter>
                            <span>
                              Order Status:{" "}
                              <span>
                                {order.status === "Txn Success"
                                  ? "Paid"
                                  : order.status}
                              </span>
                            </span>
                            <Button
                              className="btn-link float-right"
                              color="warning"
                              onClick={() => this.orderDetails(order)}
                            >
                              Order Details
                            </Button>
                          </CardFooter>
                        </Card>
                      ))}
                    </>
                  ) : (
                    <>
                      {/* <div className="text-center">
                        <h3>You haven't ordered anything yet.</h3>
                      </div> */}
                    </>
                  )}
                </>
              ) : (
                <>
                  {/* <div className="text-center">
                    <h1>Please login to view your orders</h1>
                  </div> */}
                </>
              )}
            </Container>
          </div>
          <Modal
            modalClassName="modal-black modal-xl"
            isOpen={this.state.photosModal}
            toggle={() => this.toggleModal("photosModal")}
            size="lg"
          >
            <div className="modal-header justify-content-center">
              <button
                className="close"
                onClick={() => this.toggleModal("photosModal")}
              >
                <i className="tim-icons icon-simple-remove text-white" />
              </button>
              <UncontrolledCarousel items={this.state.carouselItems} />
            </div>
          </Modal>
          <Modal
            modalClassName="modal-black modal-xl"
            isOpen={this.state.orderDetailsModal}
            toggle={() => this.toggleModal("orderDetailsModal")}
            size="lg"
          >
            <div className="modal-body justify-content-center">
              <button
                className="close"
                onClick={() => this.toggleModal("orderDetailsModal")}
              >
                <i className="tim-icons icon-simple-remove text-white" />
              </button>
              <div className="p-2" id="orderDetails" height="560px"></div>
            </div>
          </Modal>
          <Footer />
        </div>
      </>
    );
  }
}

export default Orders;
